import React, { useEffect, useState } from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { getMyInvoices, Invoice } from '../api'
import { routes } from '../App'
import { BackArrow } from '../styled/BackArrow'
import { Card } from '../styled/Card'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { invoiceMapping } from '../utils/invoiceMapping'
import { useInvoicePreview } from '../utils/apiHooks'
import { getFirstOfNextMonth } from '../utils/getFirstOfNextMonth'
import { SomeCTAButton } from '../styled/SomeCTAButton';

const SomeGrayButton = styled.button`
  border: 0 none;
  background-color: #e2e2e2;
  border-radius: 10px;
  color: black;
  margin-top: 16px;
  text-align: center;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 20px;
`

const InvoiceList: React.FC<{ invoices: Invoice[]; title: string }> = ({
  invoices,
  title
}) => {
  const { history } = useReactRouter()

  return (
    <Card title={title} arrow={false}>
      <Flex flexDirection="column">
        {invoices.map(invoice => (
          <SomeGrayButton
            onClick={() =>
              history.push(`/dashboard/billing/invoice/${invoice.id}`)
            }
          >
            {new Date(invoice.issued_on).toLocaleDateString(undefined, {
              day: 'numeric',
              month: 'long'
            })}{' '}
            - ${invoice.amount_charged} - {invoice.status.toLowerCase()}
          </SomeGrayButton>
        ))}
      </Flex>
    </Card>
  )
}

export const MemberDashboardBilling: React.FC = ({ history }: any) => {
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const invoicePreview = useInvoicePreview()

  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getMyInvoices({ token }).then(({ data }) => setInvoices(data))
  }, [])

  const unpaidInvoices: Invoice[] = invoices.filter(i =>
    invoiceMapping.unpaid.includes(i.status)
  )
  const pendingInvoices: Invoice[] = invoices.filter(i =>
    invoiceMapping.pending.includes(i.status)
  )

  // if there are any invoices in error states, they will still be displayed in the UI
  const paidAndOtherInvoices: Invoice[] = invoices.filter(
    i =>
      !invoiceMapping.unpaid.includes(i.status) &&
      !invoiceMapping.pending.includes(i.status)
  )

  return (
    <div className="App">
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Billing</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <Card title={'Billing Info'} arrow={false}>
        <SomeCTAButton
          onClick={() => history.push(routes.memberDashboardBillingInfo)}
        >
          Update Billing Info
        </SomeCTAButton>
      </Card>
      <Card title={'Next invoice'} arrow={false}>
        <div
          style={{
            position: 'absolute',
            right: '0%',
            top: '33%',
            paddingRight: '16px',
            fontSize: '16px',
            color: 'black'
          }}
        >
          {invoicePreview
            ? `${getFirstOfNextMonth()} - $${invoicePreview.amount_charged}`
            : '-'}
        </div>
      </Card>
      <InvoiceList title="unpaid invoices" invoices={unpaidInvoices} />
      <InvoiceList title="pending invoices" invoices={pendingInvoices} />
      <InvoiceList title="past invoices" invoices={paidAndOtherInvoices} />
    </div>
  )
}
