import React from 'react'
import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'

// const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]

// const items = [
//   {
//     id: 1,
//     group: 1,
//     title: 'Small Room',
//     start_time: moment(),
//     end_time: moment().add(1, 'hour')
//   },
//   {
//     id: 2,
//     group: 2,
//     title: 'Large Room 1',
//     start_time: moment().add(-0.5, 'hour'),
//     end_time: moment().add(0.5, 'hour')
//   },
//   {
//     id: 3,
//     group: 1,
//     title: 'Large',
//     start_time: moment().add(2, 'hour'),
//     end_time: moment().add(3, 'hour')
//   }
// ]

export const MeetingRoomSchedule = ({ mapGroup, mapItems }: any) => {
  return (
    //@ts-ignore
    <Timeline
      groups={mapGroup}
      items={mapItems}
      defaultTimeStart={moment().add(-12, 'hour')}
      defaultTimeEnd={moment().add(12, 'hour')}
      canMove={false}
    />
  )
}
