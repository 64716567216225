import React from 'react'
import useReactRouter from 'use-react-router'
import { userRequestCancelMembership } from '../api'
import { routes } from '../App'
import { BackArrow } from '../styled/BackArrow'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { SomeCTAButton } from '../styled/SomeCTAButton'
import { SomeParagraph } from '../styled/SomeParagraph'

export const MemberDashboardCancelPlan: React.FC = () => {
  const { history } = useReactRouter()

  const handleConfirmCancelPlan = () => {
    userRequestCancelMembership({ token: window.localStorage.getItem('Token') })
    history.push(routes.memberDashboardCancelPlanSuccess)
  }

  return (
    <div className="App">
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Cancel plan</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <ContentContainer>
        <SomeParagraph>
          Are you sure you want to cancel your Spacecubed membership?
        </SomeParagraph>

        <SomeCTAButton onClick={handleConfirmCancelPlan}>
          Yes, cancel my membership
        </SomeCTAButton>
        <SomeCTAButton
          onClick={() => history.push(routes.memberDashboardMembership)}
        >
          No, go back
        </SomeCTAButton>
      </ContentContainer>
    </div>
  )
}
