import { Spin } from 'antd'
import html2pdf from 'html2pdf.js'
import React, { useEffect, useState } from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { getInvoiceHTML } from '../api'
import { SomeCTAButton } from '../styled/SomeCTAButton';

const Invoice = styled.div`
  width: 100%;

  > .invoice-box {
    background: white;
    max-width: 80%;
  }
`

export const MemberDashboardBillingInvoiceView: React.FC = ({ match }: any) => {
  const invoiceId = match.params.invoiceId

  const [invoiceHTML, setInvoiceHTML] = useState<string>()
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getInvoiceHTML({ token, invoiceId }).then(({ data }) => {
      setInvoiceHTML(data)
    })
  }, [invoiceId])

  return (
    <div className="App">
      <Flex
        justifyContent={'center'}
        flex={1}
        flexDirection="column"
        style={{ width: '100%' }}
        alignItems="center"
        pt={5}
        pb={5}
      >
        {invoiceHTML == null ? (
          <Spin />
        ) : (
          <>
            <Invoice dangerouslySetInnerHTML={{ __html: invoiceHTML }} />
            <SomeCTAButton
              onClick={() =>
                html2pdf()
                  .from(invoiceHTML)
                  .save(`Spacecubed Invoice ${invoiceId}`)
              }
            >
              Download invoice as PDF
            </SomeCTAButton>
          </>
        )}
      </Flex>
    </div>
  )
}
