import React from 'react'
import useReactRouter from 'use-react-router'
import { routes } from '../App'
import { BackArrow } from '../styled/BackArrow'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { SomeCTAButton } from '../styled/SomeCTAButton'
import { SomeParagraph } from '../styled/SomeParagraph'

export const MemberDashboardCancelPlanSuccess: React.FC = () => {
  const { history } = useReactRouter()

  return (
    <div className="App">
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Cancel plan</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <div style={{ padding: '16px' }}>
        <SomeParagraph>
          We’re processing your membership cancellation request now. You’ll
          receive an email from a Spacecubed admin with further details. Until
          your membership cancellation is processed you'll still be able to use
          the Spacecubed Platform app as usual.
        </SomeParagraph>

        <SomeCTAButton onClick={() => history.push(routes.memberDashboardHome)}>
          Done
        </SomeCTAButton>
      </div>
    </div>
  )
}
