import React, { useState } from 'react'
import { Elements } from 'react-stripe-elements'
import { saveBillingDetails } from '../api'
import { routes } from '../App'
import { BackArrow } from '../styled/BackArrow'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'
import { CardForm } from './CardForm'

export const MemberDashboardBillingUpdate: React.FC = ({
  history,
  setUserDetails
}: any) => {
  const [billingName, setBillingName] = useState('')
  const [error, setError] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = async ({ token: stripeToken }: { token: string }) => {
    setIsSubmitting(true)
    const key = window.localStorage.getItem('Token')
    try {
      await saveBillingDetails(stripeToken, { token: key })
      // the customer was created properly in stripe and their initial charge succeeded
      setUserDetails((currentValues: any) => ({
        ...currentValues,
        billingName
      }))
      history.push(routes.memberDashboardBillingUpdateSuccess)
    } catch (error) {
      setIsSubmitting(false)
      if (error.response) {
        setError(error.response.data)
      }
    }
  }

  return (
    <>
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Edit billing details</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <div
        style={{
          margin: '16px',
          marginLeft: '24px'
        }}
      >
        <ContentContainer>
          <QuestionContainer>
            <QuestionTitle>billing name</QuestionTitle>
            <input
              type="text"
              name="billingName"
              value={billingName}
              autoFocus={true}
              onChange={e => {
                e.preventDefault()
                setBillingName(e.target.value)
              }}
              data-testid="complete-billingName-field"
            />
          </QuestionContainer>
          <QuestionContainer>
            <Elements>
              <CardForm
                handleResult={handleSubmit}
                isSubmitting={isSubmitting}
                billingName={billingName}
                errorText={error}
                actionButtonText={'Update Billing Details'}
              />
            </Elements>
          </QuestionContainer>
        </ContentContainer>
      </div>
    </>
  )
}
