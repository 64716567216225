import { Button, Icon, Input, Table, Tag } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'
import { getMembers, linkMemberNexudus, onboardMember } from '../api'
import { AdminCard } from '../styled/AdminCard'
import { AdminNav } from '../styled/AdminNav'

export const AdminDashboardMembers: React.FC = ({ history }: any) => {
  const [members, setMembers] = useState([])
  const searchInput = useRef<Input | null>()
  const [coworkerId, setCoworkerId] = useState('')
  const [searchText, setSearchText] = useState<string>('')
  const [nexudusUserId, setNexudusUserId] = useState('')
  const [wifiPassword, setWifiPassword] = useState('')

  const [showLink, setShowLink] = useState<number | null>(null)

  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getMembers({ token }).then(({ data }) => setMembers(data))
  }, [])

  const handleOnboarding = (id: number) => {
    const token = window.localStorage.getItem('Token')
    return onboardMember(id, { token })
      .then(() => getMembers({ token }))
      .then(({ data }) => setMembers(data))
  }
  const linkNexudus = () => {
    const token = window.localStorage.getItem('Token')
    return linkMemberNexudus(
      {
        userId: showLink!,
        coworkerId: parseInt(coworkerId),
        nexudusUserId: parseInt(nexudusUserId),
        wifiPassword
      },
      { token }
    ).then(() =>
      getMembers({ token }).then(({ data }) => {
        setMembers(data)
        setShowLink(null)
      })
    )
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current && searchInput.current.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })

  function handleSearch(selectedKeys, confirm) {
    confirm()
    setSearchText(selectedKeys[0])
  }

  function handleReset(clearFilters) {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: any) => <a href="javascript:;">{text}</a>,
      ...getColumnSearchProps('name')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: string, record: any) => (
        <Link to={`/admin/dashboard/members/${record.id}`}>{email}</Link>
      )
    },
    {
      title: 'Onboarding',
      key: 'onboarding',
      dataIndex: 'signup_status',
      render: (signup_status: any) =>
        signup_status === 'approved_and_onboarded' ? (
          <Tag color={'green'}>{'DONE'}</Tag>
        ) : (
          <Tag color={'red'}>{'NOT DONE'}</Tag>
        )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <span>
          {record.is_approved ? (
            <a href="javascript:;" />
          ) : (
            <a
              data-testid={`onboard-${record.email}`}
              href="javascript:;"
              onClick={() => handleOnboarding(record.id)}
            >
              Onboarding Done
            </a>
          )}
        </span>
      )
    },
    {
      title: 'Nexudus',
      key: 'nexudus',
      render: (text: any, record: any) => (
        <span>
          <a
            data-testid={`link-${record.email}`}
            href="#"
            onClick={() => setShowLink(record.id)}
          >
            {record.is_linked_to_nexudus ? 'Re-Link' : 'Link'}
          </a>
        </span>
      )
    }
  ]

  const data = members.map(({ user_profile, ...rest }: any) => ({
    ...user_profile,
    ...rest
  }))

  return (
    <div className="Admin-App">
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <AdminNav history={history} />
        <div style={{ width: '100%', padding: '16px' }}>
          <AdminCard title={'All Members'}>
            {showLink ? (
              <div
                style={{
                  border: '1px solid black',
                  padding: '8px',
                  borderRadius: '4px'
                }}
              >
                <h3>coworker id</h3>
                <input
                  type="text"
                  name="coworkerId"
                  value={coworkerId}
                  style={{ border: '1px solid black' }}
                  autoFocus={true}
                  onChange={e => {
                    e.preventDefault()
                    setCoworkerId(e.target.value)
                  }}
                  data-testid="link-coworker-id"
                />
                <h3>Nexudus user id</h3>
                <input
                  type="text"
                  name="nexudusUserId"
                  value={nexudusUserId}
                  style={{ border: '1px solid black' }}
                  autoFocus={true}
                  onChange={e => {
                    e.preventDefault()
                    setNexudusUserId(e.target.value)
                  }}
                  data-testid="link-nexudus-user-id"
                />
                <h3>Wifi Password</h3>
                <input
                  type="text"
                  name="wifiPassword"
                  value={wifiPassword}
                  style={{ border: '1px solid black' }}
                  autoFocus={true}
                  onChange={e => {
                    e.preventDefault()
                    setWifiPassword(e.target.value)
                  }}
                  data-testid="link-nexudus-wifi-password"
                />
                <button
                  style={{ fontSize: 16, margin: '8px' }}
                  data-testid="link-button"
                  onClick={() => linkNexudus()}
                >
                  Link
                </button>
              </div>
            ) : (
              <></>
            )}
            <Table columns={columns} dataSource={data} rowKey={'email'} />
          </AdminCard>
        </div>
      </div>
    </div>
  )
}
