import React, { useState } from 'react'
import { Elements } from 'react-stripe-elements'
import { saveBillingDetails } from '../api'
import { routes } from '../App'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'
import { CardForm } from './CardForm'

export const CompleteBilling: React.FC = ({ history, setUserDetails }: any) => {
  const [billingName, setBillingName] = useState('')
  const [error, setError] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = async ({ token: stripeToken }: { token: string }) => {
    setIsSubmitting(true)
    const key = window.localStorage.getItem('Token')
    try {
      await saveBillingDetails(stripeToken, { token: key })
      // the customer was created properly in stripe and their initial charge succeeded
      setUserDetails((currentValues: any) => ({
        ...currentValues,
        billingName
      }))
      history.push(routes.completeBookOnboarding)
    } catch (error) {
      setIsSubmitting(false)
      if (error.response) {
        setError(error.response.data)
      }
    }
  }

  return (
    <>
      <OnboardingHeader>
        <HeaderText>Add billing details</HeaderText>
      </OnboardingHeader>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <ContentContainer>
          <QuestionContainer style={{ paddingLeft: '8px' }}>
            <QuestionTitle>billing name</QuestionTitle>
            <input
              type="text"
              name="billingName"
              value={billingName}
              autoFocus={true}
              onChange={e => {
                e.preventDefault()
                setBillingName(e.target.value)
              }}
              data-testid="complete-billingName-field"
            />
          </QuestionContainer>
          <QuestionContainer>
            <Elements>
              <CardForm
                handleResult={handleSubmit}
                isSubmitting={isSubmitting}
                billingName={billingName}
                errorText={error}
                infoText={'You will be charged a pro-rated amount for this month. For example, if your plan is $600 per month and the current date is the 15th, you will be charged $300 now.'}
                actionButtonText={'Pay and proceed'}
              />
            </Elements>
          </QuestionContainer>
        </ContentContainer>
      </div>
    </>
  )
}
