import React from 'react'
import useReactRouter from 'use-react-router'
import { updateUserDetails } from '../api'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { Button } from '../styled/Button'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Text } from '../styled/Text'

const OnBoardingIframe = () => (
  <>
    <iframe
      title="Onboarding Scheduling"
      src="https://app.acuityscheduling.com/schedule.php?owner=11390739&calendarID=746552"
      width="100%"
      height="560"
      frameBorder="0"
    />
    <script
      src="https://embed.acuityscheduling.com/js/embed.js"
      type="text/javascript"
    />
  </>
)

export const CompleteBookOnboarding: React.FC = () => {
  const { history } = useReactRouter()
  const userDetails = useUserDetails()

  return (
    <div className="App">
      <OnboardingHeader>Book an onboarding session</OnboardingHeader>
      <OnBoardingIframe />
      <Text>Press ‘finish’ when you have made a booking.</Text>
      <Button
        data-testid="complete-next"
        onClick={() => {
          const token = window.localStorage.getItem('Token')
          return updateUserDetails(
            {
              about_you: userDetails!.aboutYou,
              job_title: userDetails!.jobTitle,
              company: userDetails!.company,
              // @ts-ignore
              interests: JSON.stringify(userDetails!.interests),
              // @ts-ignore
              skills: JSON.stringify(userDetails!.skills)
            },
            { token }
          ).then(() => {
            history.push(routes.memberDashboardHome)
          })
        }}
      >
        Finish
      </Button>
    </div>
  )
}
