import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import styled from 'styled-components'
import { routes } from '../App'
import { Button } from './Button'
import { HeaderText } from './HeaderText'

const MenuLink = styled.a`
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  margin-bottom: 32px;
  color: #303539;
`

export class BurgerMenu extends React.Component {
  public showSettings(event: any) {
    event.preventDefault()
  }

  public render() {
    const isWhite = window.location.href.indexOf('meeting-rooms') !== -1
    return (
      <Menu
        right
        width={'100%'}
        // @ts-ignore
        styles={{
          bmBurgerBars: {
            background: isWhite ? '#FFFFFF' : '#707070'
          }
        }}
      >
        <HeaderText
          style={{
            position: 'absolute',
            top: '24px',
            left: '50%',
            marginLeft: '-70px'
          }}
        >
          Spacecubed
        </HeaderText>
        <MenuLink
          id="home"
          className="menu-item"
          href={routes.memberDashboardHome}
        >
          Home
        </MenuLink>
        <MenuLink
          id="billing"
          className="menu-item"
          href={routes.memberDashboardBilling}
        >
          Billing
        </MenuLink>
        <MenuLink
          id="membership"
          className="menu-item"
          href={routes.memberDashboardMembership}
        >
          Membership
        </MenuLink>
        <MenuLink
          className="menu-item--small"
          href={routes.memberDashboardProfile}
        >
          Profile
        </MenuLink>
        <Button
          data-testid="complete-next"
          onClick={() => {
            // @ts-ignore
            this.props.history.push(routes.memberDashboardMeetingRooms)
          }}
        >
          Find a Meeting Room
        </Button>
      </Menu>
    )
  }
}
