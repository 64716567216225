import { Drawer } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

const SelectButton = styled.button`
  background: #eff4fb;
  border-radius: 13px;
  color: #525961;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: 0 none;
  flex: 3;
  width: 100%;
`
const SelectName = styled.div`
  color: #41474d;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  flex: 1;
`

const Cell = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  padding: 16px;
  align-self: center;
  border-bottom: 1px solid #e2e2e2;
`

const SelectedCell = styled.div`
  background: #ecac66;
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  padding: 16px;
  align-self: center;
  border-bottom: 1px solid #e2e2e2;
`

export const SelectOption = ({
  items,
  name,
  selectedIndex,
  onSelect
}: {
  items: string[]
  name: string
  selectedIndex: number
  onSelect: (n: number) => any
}): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false)

  const selectedString = items[selectedIndex]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <SelectName>{name}</SelectName>
      <SelectButton onClick={() => setIsVisible(true)}>
        {selectedString}
      </SelectButton>
      <Drawer
        width={200}
        placement={'bottom'}
        closable={false}
        onClose={() => setIsVisible(false)}
        visible={isVisible}
      >
        {items.map((e, i) =>
          i === selectedIndex ? (
            <SelectedCell
              key={e}
              onClick={() => {
                onSelect(i)
                setIsVisible(false)
              }}
            >
              {e}
            </SelectedCell>
          ) : (
            <Cell
              key={e}
              onClick={() => {
                onSelect(i)
                setIsVisible(false)
              }}
            >
              {e}
            </Cell>
          )
        )}
      </Drawer>
    </div>
  )
}
