import React, { useEffect, useState } from 'react'
import { userRequestPlanChange } from '../api'
import { routes } from '../App'
import { Plan } from '../interfaces'
import { BackArrow } from '../styled/BackArrow'
import { Card } from '../styled/Card'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Text } from '../styled/Text'
import { usePlans, useUserProfile } from '../utils/apiHooks'

export const MemberDashboardChangePlan: React.FC = ({
  history,
  setUserDetails
}: any) => {
  const userDetails = useUserProfile()
  const plans = usePlans()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  useEffect(() => {
    if (userDetails && plans.length > 0) {
      setSelectedPlan(plans.find(plan => plan.id === userDetails.plan))
    }
  }, [userDetails, plans])

  const handleSelectPlan = ({ id }: { id: number }) => {
    setUserDetails((currentValues: any) => ({
      ...currentValues,
      ...{ plan: Number(id) }
    }))
    userRequestPlanChange(id, { token: window.localStorage.getItem('Token') })
  }

  return (
    <div className="App">
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Change Plan</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <ContentContainer>
        {selectedPlan &&
          plans.map(({ id, name, description, rate_string }: Plan) => {
            const isSelectedPlan = id === selectedPlan.id
            return (
              <Card
                outline={isSelectedPlan}
                title={isSelectedPlan ? `Your current plan - ${name}` : name}
                key={id}
                arrow={!isSelectedPlan}
                onPress={() => {
                  if (!isSelectedPlan) {
                    handleSelectPlan({ id })
                    history.push(routes.memberDashboardChangePlanSuccess)
                  }
                }}
                testID={name}
              >
                <Text color={'black'} verticalSpacing={16}>
                  {description}
                </Text>
                <Text color={'Gray'} verticalSpacing={16}>
                  {rate_string}
                </Text>
              </Card>
            )
          })}
      </ContentContainer>
    </div>
  )
}
