import * as Sentry from '@sentry/browser'
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import './App.css'
import { UserDetailsContext } from './context'
import { User } from './interfaces'
import { AdminDashboardAwaitingMembers } from './pages/AdminDashboardAwaitingMembers'
import { AdminDashboardInvoices } from './pages/AdminDashboardInvoices'
import { AdminDashboardInvoiceView } from './pages/AdminDashboardInvoiceView'
import { AdminDashboardMeetingRooms } from './pages/AdminDashboardMeetingRooms'
import { AdminDashboardMemberProfile } from './pages/AdminDashboardMemberProfile'
import { AdminDashboardMembers } from './pages/AdminDashboardMembers'
import { AdminDashboardUnpaidInvoices } from './pages/AdminDashboardUnpaidInvoices'
import { CompleteBilling } from './pages/CompleteBilling'
import { CompleteBookOnboarding } from './pages/CompleteBookOnboarding'
import { CompleteLanding } from './pages/CompleteLanding'
import { CompleteProfile1 } from './pages/CompleteProfile1'
import { CompleteProfile2 } from './pages/CompleteProfile2'
import { CompleteProfile3 } from './pages/CompleteProfile3'
import { Login } from './pages/Login'
import { MemberAwaitingApproval } from './pages/MemberAwaitingApproval'
import { MemberDashboardBilling } from './pages/MemberDashboardBilling'
import { MemberDashboardBillingInfo } from './pages/MemberDashboardBillingInfo'
import { MemberDashboardBillingInvoice } from './pages/MemberDashboardBillingInvoice'
import { MemberDashboardBillingInvoiceView } from './pages/MemberDashboardBillingInvoiceView'
import { MemberDashboardBillingUnpaidInvoiceChargeAgain } from './pages/MemberDashboardBillingUnpaidInvoiceChargeAgain'
import { MemberDashboardBillingUpdate } from './pages/MemberDashboardBillingUpdate'
import { MemberDashboardBillingUpdateSuccess } from './pages/MemberDashboardBillingUpdateSuccess'
import { MemberDashboardCancelPlan } from './pages/MemberDashboardCancelPlan'
import { MemberDashboardCancelPlanSuccess } from './pages/MemberDashboardCancelPlanSuccess'
import { MemberDashboardChangePlan } from './pages/MemberDashboardChangePlan'
import { MemberDashboardChangePlanSuccess } from './pages/MemberDashboardChangePlanSuccess'
import { MemberDashboardHome } from './pages/MemberDashboardHome'
import { MemberDashboardMeetingRoomHistory } from './pages/MemberDashboardMeetingRoomHistory'
import { MemberDashboardMeetingRooms } from './pages/MemberDashboardMeetingRooms'
import { MemberDashboardMeetingRoomView } from './pages/MemberDashboardMeetingRoomView'
import { MemberDashboardMembership } from './pages/MemberDashboardMembership'
import { MemberDashboardProfile } from './pages/MemberDashboardProfile'
import { OnboardingAcceptTC } from './pages/OnboardingAcceptTC'
import { OnboardingChoosePlan } from './pages/OnboardingChoosePlan'
import { OnboardingDetails } from './pages/OnboardingDetails'
import { OnboardingEmail } from './pages/OnboardingEmail'
import { OnboardingName } from './pages/OnboardingName'
import { OnboardingStartDate } from './pages/OnboardingStartDate'
import { OnboardingThankyou } from './pages/OnboardingThankyou'
import { TermsAndConditions } from './pages/TermsAndConditions'
import { BurgerMenu } from './styled/BurgerMenu'

Sentry.init({
  dsn: 'https://6a0a6b35fc0b476595435d1f11cac946@sentry.io/1487976'
})

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env

const stripeKey =
  REACT_APP_STRIPE_PUBLIC_KEY !== undefined ? REACT_APP_STRIPE_PUBLIC_KEY : ''

export const routes = {
  login: '/login',
  plan: '/start/plan',
  email: '/start/email',
  name: '/start/name',
  details: '/start/details',
  startDate: '/start/start-date',
  termsAndConditions: '/terms-and-conditions',
  acceptTermsAndConditions: '/start/terms-and-conditions',
  thankyou: '/start/thank-you',
  completeLanding: '/complete',
  completeProfile1: '/complete/profile/1',
  completeProfile2: '/complete/profile/2',
  completeProfile3: '/complete/profile/3',
  completeBilling: '/complete/billing',
  completeBookOnboarding: '/complete/book-onboarding',
  memberAwaitingApproval: '/complete/awaiting-approval',
  memberDashboardHome: '/dashboard/home',
  memberDashboardBilling: '/dashboard/billing',
  memberDashboardMembership: '/dashboard/membership',
  memberDashboardChangePlan: '/dashboard/change-plan',
  memberDashboardChangePlanSuccess: '/dashboard/change-plan/success',
  memberDashboardCancelPlan: '/dashboard/cancel-plan',
  memberDashboardCancelPlanSuccess: '/dashboard/cancel-plan/success',
  memberDashboardProfile: '/dashboard/profile',
  memberDashboardMeetingRooms: '/dashboard/meeting-rooms',
  memberDashboardMeetingRoomView: '/dashboard/meeting-rooms/:memberId',
  memberDashboardMeetingRoomHistory: '/dashboard/meeting-rooms/history',
  memberDashboardBillingInfo: '/dashboard/billing/info',
  memberDashboardBillingUpdate: '/dashboard/billing/update',
  memberDashboardBillingUpdateSuccess: '/dashboard/billing/update/success',
  memberDashboardBillingInvoice: '/dashboard/billing/invoice/:invoiceId',
  memberDashboardBillingInvoiceView:
    '/dashboard/billing/invoice/:invoiceId/view',
  memberDashboardBillingUnpaidInvoiceChargeAgain:
    '/dashboard/billing/unpaid-invoice-complete',
  adminDashboard: '/admin/dashboard',
  adminDashboardAwaitingMembers: '/admin/dashboard/awaiting-members',
  adminDashboardMeetingRooms: '/admin/dashboard/meeting-rooms',
  adminDashboardMembers: '/admin/dashboard/members',
  adminDashboardMemberProfile: '/admin/dashboard/members/:memberId',
  adminDashboardInvoices: '/admin/dashboard/invoices',
  adminDashboardUnpaidInvoices: '/admin/dashboard/unpaid-invoices',
  adminDashboardInvoiceView: '/admin/dashboard/invoice/:invoiceId/view'
}

const StartRoutes = ({
  setUserDetails
}: {
  setUserDetails: React.Dispatch<React.SetStateAction<User>>
}) => (
  <Switch>
    <Route
      exact
      path={routes.plan}
      component={(props: any) => (
        <OnboardingChoosePlan {...props} setUserDetails={setUserDetails} />
      )}
    />
    <Route
      exact
      path={routes.email}
      component={(props: any) => (
        <OnboardingEmail {...props} setUserDetails={setUserDetails} />
      )}
    />
    <Route
      exact
      path={routes.name}
      component={(props: any) => (
        <OnboardingName {...props} setUserDetails={setUserDetails} />
      )}
    />
    <Route
      exact
      path={routes.details}
      component={(props: any) => (
        <OnboardingDetails {...props} setUserDetails={setUserDetails} />
      )}
    />
    <Route
      exact
      path={routes.startDate}
      component={(props: any) => (
        <OnboardingStartDate {...props} setUserDetails={setUserDetails} />
      )}
    />
    <Route
      exact
      path={routes.acceptTermsAndConditions}
      component={(props: any) => (
        <OnboardingAcceptTC {...props} setUserDetails={setUserDetails} />
      )}
    />
    <Route exact path={routes.thankyou} component={OnboardingThankyou} />
  </Switch>
)

const CompleteProfileRoutes = ({ setUserDetails }: any) => (
  <Switch>
    <Route
      exact
      path={routes.completeProfile1}
      component={(props: any) => (
        <CompleteProfile1 setUserDetails={setUserDetails} {...props} />
      )}
    />
    <Route
      exact
      path={routes.completeProfile2}
      component={(props: any) => (
        <CompleteProfile2 setUserDetails={setUserDetails} {...props} />
      )}
    />
    <Route
      exact
      path={routes.completeProfile3}
      component={(props: any) => (
        <CompleteProfile3 setUserDetails={setUserDetails} {...props} />
      )}
    />
    <Route
      exact
      path={routes.completeBilling}
      component={(props: any) => (
        <CompleteBilling setUserDetails={setUserDetails} {...props} />
      )}
    />
    <Route
      exact
      path={routes.completeBookOnboarding}
      component={(props: any) => (
        <CompleteBookOnboarding setUserDetails={setUserDetails} {...props} />
      )}
    />
    />
    <Route
      exact
      path={routes.completeLanding}
      component={(props: any) => (
        <CompleteLanding setUserDetails={setUserDetails} {...props} />
      )}
    />
    <Route
      exact
      path={routes.memberAwaitingApproval}
      component={(props: any) => (
        <MemberAwaitingApproval setUserDetails={setUserDetails} {...props} />
      )}
    />
  </Switch>
)

const MemberDashboardRoutes = ({ setUserDetails }: any) => (
  <>
    <Route
      path={'/dashboard'}
      component={(props: any) => (
        <BurgerMenu setUserDetails={setUserDetails} {...props} />
      )}
    />
    <Switch>
      <Route
        exact
        path="/dashboard"
        render={() => <Redirect to={routes.memberDashboardHome} />}
      />
      <Route
        exact
        path={routes.memberDashboardHome}
        component={(props: any) => (
          <MemberDashboardHome setUserDetails={setUserDetails} {...props} />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardProfile}
        component={(props: any) => (
          <MemberDashboardProfile setUserDetails={setUserDetails} {...props} />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardMeetingRooms}
        component={(props: any) => (
          <MemberDashboardMeetingRooms
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardMeetingRoomHistory}
        component={MemberDashboardMeetingRoomHistory}
      />
      <Route
        exact
        path={routes.memberDashboardMeetingRoomView}
        component={(props: any) => (
          <MemberDashboardMeetingRoomView
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardBilling}
        component={(props: any) => (
          <MemberDashboardBilling setUserDetails={setUserDetails} {...props} />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardBillingInfo}
        component={(props: any) => (
          <MemberDashboardBillingInfo
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardBillingUpdate}
        component={(props: any) => (
          <MemberDashboardBillingUpdate
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardBillingUpdateSuccess}
        component={MemberDashboardBillingUpdateSuccess}
      />
      <Route
        exact
        path={routes.memberDashboardBillingInvoice}
        component={(props: any) => (
          <MemberDashboardBillingInvoice
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardBillingInvoiceView}
        component={(props: any) => (
          <MemberDashboardBillingInvoiceView
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardBillingUnpaidInvoiceChargeAgain}
        component={(props: any) => (
          <MemberDashboardBillingUnpaidInvoiceChargeAgain
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardMembership}
        component={(props: any) => (
          <MemberDashboardMembership
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardChangePlan}
        component={(props: any) => (
          <MemberDashboardChangePlan
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardChangePlanSuccess}
        component={(props: any) => (
          <MemberDashboardChangePlanSuccess
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardCancelPlan}
        component={(props: any) => (
          <MemberDashboardCancelPlan
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={routes.memberDashboardCancelPlanSuccess}
        component={(props: any) => (
          <MemberDashboardCancelPlanSuccess
            setUserDetails={setUserDetails}
            {...props}
          />
        )}
      />
    </Switch>
  </>
)

const App: React.FC = () => {
  const [userDetails, setUserDetails] = useState<User>({
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    company: '',
    skills: [],
    interests: [],
    aboutYou: '',
    profilePictureUrl: '',
    mobileNumber: '',
    startDate: new Date()
  })

  // TODO: fix this up, atm prevents user jumping ahead
  useEffect(() => {
    if (
      userDetails.email === '' &&
      window.location.href.indexOf(routes.plan) === -1 &&
      window.location.href.indexOf(routes.completeLanding) === -1 &&
      window.location.href.indexOf(routes.termsAndConditions) === -1 &&
      window.location.href.indexOf('/dashboard') === -1 &&
      window.location.href.indexOf('login') === -1
    ) {
      window.location.href = routes.plan
    }
  }, [userDetails.email])

  return (
    <StripeProvider apiKey={stripeKey}>
      <Router>
        <Route exact path={routes.login} component={Login} />
        <Route
          exact
          path={routes.termsAndConditions}
          component={TermsAndConditions}
        />
        <Route
          exact
          path={routes.adminDashboardAwaitingMembers}
          component={AdminDashboardAwaitingMembers}
        />
        <Route
          exact
          path={routes.adminDashboardMembers}
          component={AdminDashboardMembers}
        />
        <Route
          exact
          path={routes.adminDashboardInvoices}
          component={AdminDashboardInvoices}
        />
        <Route
          exact
          path={routes.adminDashboardUnpaidInvoices}
          component={AdminDashboardUnpaidInvoices}
        />
        <Route
          exact
          path={routes.adminDashboardMeetingRooms}
          component={AdminDashboardMeetingRooms}
        />
        <Route
          exact
          path={routes.adminDashboardMemberProfile}
          component={AdminDashboardMemberProfile}
        />
        <Route
          exact
          path={routes.adminDashboardInvoiceView}
          component={AdminDashboardInvoiceView}
        />
        <UserDetailsContext.Provider value={userDetails}>
          <Route exact path="/" render={() => <Redirect to={routes.login} />} />
          <Route
            path={'/start'}
            component={(props: any) => (
              <StartRoutes setUserDetails={setUserDetails} {...props} />
            )}
          />
          <Route
            path={'/complete'}
            component={() => (
              <CompleteProfileRoutes setUserDetails={setUserDetails} />
            )}
          />
          <Route
            path={'/dashboard'}
            component={() => (
              <MemberDashboardRoutes setUserDetails={setUserDetails} />
            )}
          />
        </UserDetailsContext.Provider>
      </Router>
    </StripeProvider>
  )
}

export default App
