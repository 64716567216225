import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getPlans } from '../api'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { Plan, User } from '../interfaces'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Progress } from '../styled/Progress'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'

export const OnboardingEmail: React.FC = ({ history, setUserDetails }: any) => {
  const userDetails = useUserDetails()
  const [plans, setPlans] = useState<Plan[]>([])

  useEffect(() => {
    getPlans().then(({ data }: { data: Plan[] }) => setPlans(data))
  }, [])

  const planName = () => {
    if (userDetails) {
      const plan = plans.find(({ id }) => id === userDetails.plan)
      if (plan !== undefined) {
        return plan.name
      }
    }
  }

  return (
    <div className="App">
      <OnboardingHeader>
        {planName() && (
          <HeaderText>
            You’re starting a <b>{planName()}</b> membership
          </HeaderText>
        )}
      </OnboardingHeader>
      <Progress numberOfSteps={6} currentStep={2} />
      <Formik
        initialValues={{ email: userDetails!.email }}
        validate={(values): User => {
          const errors: any = {}

          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            ...values
          }))
          history.push(routes.name)
        }}
      >
        {({ isSubmitting }) => (
          <ContentContainer>
            <Form style={{ width: '100%', height: '100%' }}>
              <QuestionContainer>
                <QuestionTitle>email</QuestionTitle>
                <Field
                  type="email"
                  name="email"
                  autoFocus={true}
                  data-testid="onboarding-email-field"
                  placeholder="Enter your email address"
                />
                <ErrorMessage name="email" component="div" />
              </QuestionContainer>
              <Button
                type="submit"
                data-testid="onboarding-next"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </Form>
          </ContentContainer>
        )}
      </Formik>
    </div>
  )
}
