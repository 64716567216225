import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Box } from 'rebass'
import {
  bookMeetingRoomAdmin,
  getBookingsAdmin,
  getMeetingRoomsAdmin
} from '../api'
import { AdminCard } from '../styled/AdminCard'
import { AdminNav } from '../styled/AdminNav'
import { MeetingRoomSchedule } from '../styled/MeetingRoomSchedule'

function removeDuplicated(arr: any, key = 'id') {
  const map = new Map()
  arr.map((el: any) => {
    if (!map.has(el[key])) {
      map.set(el[key], el)
    }
  })
  // @ts-ignore
  return [...map.values()]
}

export const AdminDashboardMeetingRooms: React.FC = ({ history }: any) => {
  const [meetingRooms, setMeetingRooms] = useState([])
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [userEmail, setUserEmail] = useState()
  const [roomId, setRoomId] = useState()
  const [bookings, setBookings] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isBookingRoom, setIsBookingRoom] = useState(false)

  // effect to re-fetch the latest meeting rooms and bookings from the API
  useEffect(() => {
    // only run the update effect if we're not in a loading state
    if (isBookingRoom === false) {
      const token = window.localStorage.getItem('Token')
      Promise.all([
        getBookingsAdmin({ token }),
        getMeetingRoomsAdmin({ token })
      ]).then(([bookings, meetingRooms]: any) => {
        setRoomId(meetingRooms[0].roomId)
        setBookings(bookings)
        setMeetingRooms(meetingRooms)
      })
    }
  }, [isBookingRoom])

  const makeBooking = () => {
    const token = window.localStorage.getItem('Token')
    setErrorMessage('')
    setIsBookingRoom(true)
    bookMeetingRoomAdmin(
      {
        nexudusResourceId: parseInt(roomId),
        fromTime: startTime,
        toTime: endTime,
        userEmail: userEmail ? userEmail.trim() : ''
      },
      { token }
    )
      .then(() => setIsBookingRoom(false))
      .catch(e => {
        setIsBookingRoom(false)
        setErrorMessage(e.data)
      })
  }

  const mapItems = bookings.map((x: any) => ({
    title: x.bookedBy,
    group: x.roomId,
    id: x.bookingId,
    start_time: moment(x.startTime),
    end_time: moment(x.endTime)
  }))
  const mapGroup = removeDuplicated(
    meetingRooms.map((x: any) => ({
      title: x.name,
      id: x.roomId
    }))
  )

  return (
    <div className="Admin-App">
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <AdminNav history={history} />
        <div style={{ width: 'calc(100vw - 200px)', padding: '16px' }}>
          <AdminCard title={'Meeting rooms'}>
            <div
              style={{
                border: '1px solid black',
                padding: '8px',
                borderRadius: '4px'
              }}
            >
              <h3>Book Meeting Room</h3>
              <select
                data-testid="booking-select-room-id"
                value={roomId}
                onChange={e => {
                  e.preventDefault()
                  setRoomId(e.target.value)
                }}
              >
                {mapGroup.map(({ id, title }) => (
                  <option value={id}>{title}</option>
                ))}
              </select>
              <h3>User email</h3>
              <input
                type="text"
                name="userEmail"
                value={userEmail}
                style={{ border: '1px solid black' }}
                autoFocus={true}
                onChange={e => {
                  e.preventDefault()
                  setUserEmail(e.target.value)
                }}
                data-testid="booking-user-email"
              />
              <DatePicker
                selected={startTime}
                onChange={(d: Date) => {
                  setStartTime(d)
                }}
                timeIntervals={15}
                showTimeSelect
                dateFormat="Pp"
              />
              {'to    '}
              <DatePicker
                selected={endTime}
                onChange={(d: Date) => {
                  setEndTime(d)
                }}
                timeIntervals={15}
                showTimeSelect
                dateFormat="Pp"
              />
              <button
                style={{ fontSize: 16, margin: '8px' }}
                data-testid="make-booking-button"
                onClick={makeBooking}
              >
                Make Booking
              </button>
              {isBookingRoom ? <p>booking room...</p> : <></>}
              {errorMessage === '' ? (
                <></>
              ) : (
                <>
                  <p style={{ color: 'red' }}>{errorMessage}</p>
                  <p style={{ color: 'red' }}>
                    {'Error booking a room, have you linked the nexudus id?'}
                  </p>
                </>
              )}
            </div>
            <Box style={{ overflow: 'hidden', width: 'calc(100%)' }}>
              <MeetingRoomSchedule mapItems={mapItems} mapGroup={mapGroup} />
            </Box>
          </AdminCard>
        </div>
      </div>
    </div>
  )
}
