import { format } from 'date-fns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { createNewUser, sendEmailForApproval, updateUserDetails } from '../api'
import { getTermsAndConditions } from '../api'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { Button } from '../styled/Button'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Progress } from '../styled/Progress'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'
import { Text } from '../styled/Text'

export const OnboardingAcceptTC: React.FC = ({ history }: any) => {
  const userDetails = useUserDetails()
  const [tcId, setTcId] = useState(0)
  useEffect(() => {
    getTermsAndConditions().then(({ data }: any) => setTcId(data[0].id))
  }, [])

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Create a password</HeaderText>
      </OnboardingHeader>
      <Progress numberOfSteps={6} currentStep={6} />
      <Formik
        initialValues={{ password: '' }}
        validate={values => {
          const errors: any = {}

          if (!values.password) {
            errors.password = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          createNewUser({
            email: userDetails!.email,
            username: userDetails!.email,
            password1: values.password,
            password2: values.password,
            first_name: userDetails!.firstName,
            last_name: userDetails!.lastName
          })
            .then(({ data: { key } }) => {
              window.localStorage.setItem('Token', key)

              let start_date
              if (userDetails!.startDate) {
                start_date = format(userDetails!.startDate, 'YYYY-MM-DD')
              }

              return updateUserDetails(
                {
                  plan: userDetails!.plan,
                  postcode: userDetails!.postcode,
                  mobile_number: userDetails!.mobileNumber,
                  start_date,
                  agreed_tc: tcId
                },
                { token: key }
              ).then(() => sendEmailForApproval({ token: key }))
            })
            .then(() => {
              setSubmitting(false)

              history.push(routes.thankyou)
            })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <QuestionContainer>
                <QuestionTitle>password</QuestionTitle>
                <Field
                  type="password"
                  name="password"
                  autoFocus={true}
                  data-testid="onboarding-password-field"
                  placeholder="Type your password here"
                />
                <ErrorMessage name="password" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <Text>
                  By pressing finish, you agree to the
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={routes.termsAndConditions}
                  >
                    {' Terms and Conditions'}
                  </a>
                </Text>
              </QuestionContainer>
              <Button
                type="submit"
                data-testid="onboarding-next"
                disabled={isSubmitting}
              >
                Finish
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
