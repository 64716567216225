import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { User } from '../interfaces'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Progress } from '../styled/Progress'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'

export const OnboardingDetails: React.FC = ({
  history,
  setUserDetails
}: any) => {
  const userDetails = useUserDetails()

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Tell us about yourself</HeaderText>
      </OnboardingHeader>
      <Progress numberOfSteps={6} currentStep={4} />
      <Formik
        initialValues={{
          mobileNumber: userDetails!.mobileNumber,
          postcode: userDetails!.postcode
        }}
        validate={(values): User => {
          const errors: any = {}
          if (!values.mobileNumber) {
            errors.mobileNumber = 'Required'
          }
          if (!values.postcode) {
            errors.postcode = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            ...values,
            ...{ mobileNumber: `+61${values.mobileNumber}` }
          }))
          history.push(routes.startDate)
        }}
      >
        {({ isSubmitting }) => (
          <ContentContainer>
            <Form>
              <QuestionContainer>
                <QuestionTitle>mobile number</QuestionTitle>
                <span>
                  {'+61 '}
                  <Field
                    type="tel"
                    name="mobileNumber"
                    data-testid="onboarding-mobileNumber-field"
                    placeholder="Enter your mobile number"
                  />
                </span>
                <ErrorMessage name="mobileNumber" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>postcode</QuestionTitle>
                <Field
                  type="number"
                  name="postcode"
                  data-testid="onboarding-postcode-field"
                  placeholder="Enter your postcode"
                />
                <ErrorMessage name="postcode" component="div" />
              </QuestionContainer>
              <Button
                type="submit"
                data-testid="onboarding-next"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </Form>
          </ContentContainer>
        )}
      </Formik>
    </div>
  )
}
