import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../App'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Text } from '../styled/Text'

export const CompleteLanding: React.FC = () => {
  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Complete your profile</HeaderText>
      </OnboardingHeader>
      <ContentContainer>
        <Text>
          Your membership application to Riff was accepted! We need to complete
          your profile, get your billing details and book an onboarding time to
          give you a tour of the workspace.
        </Text>
        <Link to={routes.completeProfile1}>
          <Button data-testid="complete-next">Next</Button>
        </Link>
      </ContentContainer>
    </div>
  )
}
