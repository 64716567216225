import 'antd/dist/antd.css'
import React  from 'react'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory'
import { InvoiceStatus } from '../api'
import { AdminCard } from '../styled/AdminCard'
import { AdminNav } from '../styled/AdminNav'
import { isSameMonth, format } from 'date-fns'
import { AdminInvoiceTable } from '../components/AdminInvoiceTable';
import { useInvoices } from '../utils/apiHooks';

const BigCard = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
`

const CardTitleText = styled.h3`
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  color: #737e8c;
  margin-bottom: 13px;
`

export const getInvoiceStatusColor = (status: InvoiceStatus) => {
  if (status === InvoiceStatus.Paid) {
    return 'green'
  }
  if (status === InvoiceStatus.Issued) {
    return 'blue'
  }
  return 'red'
}

export const AdminDashboardInvoices: React.FC = () => {
  const { history } = useReactRouter()
  const invoices = useInvoices()

  const viewMonths = [
    { month: new Date(2019, 0), amount: 0 },
    { month: new Date(2019, 1), amount: 0 },
    { month: new Date(2019, 2), amount: 0 },
    { month: new Date(2019, 3), amount: 0 },
    { month: new Date(2019, 4), amount: 0 },
    { month: new Date(2019, 5), amount: 0 },
    { month: new Date(2019, 6), amount: 0 },
    { month: new Date(2019, 7), amount: 0 },
    { month: new Date(2019, 8), amount: 0 },
    { month: new Date(2019, 9), amount: 0 },
    { month: new Date(2019, 10), amount: 0 },
    { month: new Date(2019, 11), amount: 0 }
  ]

  const totalRevenueData = viewMonths.map(({ month }) => ({
    month,
    amount: invoices
      .filter(({ issued_on }) => isSameMonth(new Date(issued_on), month))
      .reduce((acc, { subtotal }) => acc + subtotal, 0)
  }))

  const totalCustomerData = viewMonths.map(({ month }) => ({
    month,
    amount: invoices.filter(({ issued_on }) =>
      isSameMonth(new Date(issued_on), month)
    ).length
  }))

  return (
    <div className="Admin-App">
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <AdminNav history={history} />
        <div style={{ width: '100%', padding: '16px' }}>
          <AdminCard title={'Invoices'}>
            <AdminInvoiceTable invoices={invoices} />
          </AdminCard>
          <BigCard>
            <CardTitleText>Number of Customers</CardTitleText>
            <VictoryChart
              height={300}
              width={560}
              domainPadding={{ x: 30, y: 30 }}
            >
              <VictoryLine
                style={{
                  data: { stroke: '#5B85F9' },
                  parent: { border: '1px solid #ccc' }
                }}
                data={totalCustomerData.map(({ month, amount }) => ({
                  x: format(new Date(month), 'MMM'),
                  y: amount
                }))}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(tick: any) => `${tick}`}
              />
              <VictoryAxis
                tickFormat={totalRevenueData.map(({ month }) =>
                  format(new Date(month), 'MMM')
                )}
              />
            </VictoryChart>
          </BigCard>
          <BigCard>
            <CardTitleText>total revenue</CardTitleText>
            <VictoryChart
              height={300}
              width={560}
              domainPadding={{ x: 30, y: 30 }}
            >
              <VictoryLine
                style={{
                  data: { stroke: '#5B85F9' },
                  parent: { border: '1px solid #ccc' }
                }}
                data={totalRevenueData.map(({ month, amount }) => ({
                  x: format(new Date(month), 'MMM'),
                  y: amount
                }))}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(tick: any) => `${tick}`}
              />
              <VictoryAxis
                tickFormat={totalRevenueData.map(({ month }) =>
                  format(new Date(month), 'MMM')
                )}
              />
            </VictoryChart>
          </BigCard>
        </div>
      </div>
    </div>
  )
}
