/**
 * Returns the 1st of the following month to inform the user when their next invoice will be charged
 */
export const getFirstOfNextMonth = (): string => {
  let now = new Date()
  if (now.getMonth() === 11) {
    now = new Date(now.getFullYear() + 1, 0, 1)
  } else {
    now = new Date(now.getFullYear(), now.getMonth() + 1, 1)
  }
  return now.toLocaleDateString(undefined, { day: 'numeric', month: 'long' })
}
