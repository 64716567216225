import React from 'react'
import styled from 'styled-components'

export const Progress = ({
  numberOfSteps,
  currentStep
}: {
  numberOfSteps: number
  currentStep: number
}): JSX.Element => {
  const numberOfRemainingSteps = numberOfSteps - currentStep
  const numberOfCompletedSteps = currentStep - 1

  return (
    <ProgressContainer>
      {[...Array(numberOfCompletedSteps)].map(() => (
        <BlankStep />
      ))}
      <CompletedStep />
      {[...Array(numberOfRemainingSteps)].map(() => (
        <BlankStep />
      ))}
    </ProgressContainer>
  )
}

const ProgressContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CompletedStep = styled.div`
  background: #5b85f9;
  margin: 4px;
  height: 6px;
  width: 6px;
  border-radius: 100%;
`

const BlankStep = styled.div`
  background: #c4cfe0;
  margin: 4px;
  height: 6px;
  width: 6px;
  border-radius: 100%;
`
