import React from 'react'
import styled from 'styled-components'
import { routes } from '../App'
import { Card } from '../styled/Card'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import useReactRouter from 'use-react-router'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import {
  useTotalUnpaidInvoices,
  useInvoicePreview,
  useRemainingMeetingRoomQuota,
  useUserProfile
} from '../utils/apiHooks'
import { getFirstOfNextMonth } from '../utils/getFirstOfNextMonth'
import { USER_SUPPORT_LINK } from '../utils/constants';

const DollarAmount = styled.h2`
  font-size: 48px;
  line-height: 48px;
  color: #373f49;
  text-align: left;
`

const SubText = styled.p`
  font-size: 18px;
  line-height: 18px;
  text-align: left;
  color: #8b96a3;
`

export const MemberDashboardHome: React.FC = () => {
  const { history } = useReactRouter()

  const invoicePreview = useInvoicePreview()
  const totalUnpaidInvoices = useTotalUnpaidInvoices()
  const meetingRoomQuota = useRemainingMeetingRoomQuota()
  const userProfile = useUserProfile()

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Home</HeaderText>
      </OnboardingHeader>
      <p style={{ padding: '16px' }} data-testid={'contact-us'}>
        Issues? <a href={USER_SUPPORT_LINK}>Contact Us</a>
      </p>
      {invoicePreview == null || totalUnpaidInvoices == null ? (
        <CenteredSpinner />
      ) : (
        <>
          <Card
            title={'next invoice'}
            onPress={() => history.push(routes.memberDashboardBilling)}
          >
            <DollarAmount>${invoicePreview.amount_charged}</DollarAmount>
            <SubText>on {getFirstOfNextMonth()}</SubText>
          </Card>
          <Card title={'unpaid invoices'} arrow={false} onPress={() => {}}>
            <DollarAmount>
              {totalUnpaidInvoices ? '$' + totalUnpaidInvoices : '-'}
            </DollarAmount>
            <SubText />
          </Card>
          <Card
            title={'booking credits'}
            onPress={() => history.push(routes.memberDashboardMeetingRooms)}
          >
            {meetingRoomQuota == null ? (
              '-'
            ) : (
              <>
                <DollarAmount>
                  {parseFloat(meetingRoomQuota.credits_remaining) > 0
                    ? meetingRoomQuota.credits_remaining
                    : 0}
                </DollarAmount>
                <SubText>remaining this month</SubText>
              </>
            )}
          </Card>
          {userProfile && (
            <Card title={'wifi password'} arrow={false}>
              <>
                <SubText>
                  {userProfile.wifi_password || 'password not yet set'}
                </SubText>
              </>
              )}
            </Card>
          )}
        </>
      )}
    </div>
  )
}
