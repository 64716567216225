import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { User } from '../interfaces'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'

export const CompleteProfile1: React.FC = ({
  history,
  setUserDetails
}: any) => {
  const userDetails = useUserDetails()

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Complete your profile</HeaderText>
      </OnboardingHeader>
      <Formik
        initialValues={{
          jobTitle: userDetails!.jobTitle,
          company: userDetails!.company
        }}
        validate={(values): User => {
          const errors: any = {}
          if (!values.jobTitle) {
            errors.jobTitle = 'Required'
          }
          if (!values.company) {
            errors.company = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            ...values
          }))
          history.push(routes.completeProfile2)
        }}
      >
        {({ isSubmitting }) => (
          <ContentContainer>
            <Form>
              <QuestionContainer>
                <QuestionTitle>job title</QuestionTitle>
                <Field
                  type="text"
                  name="jobTitle"
                  data-testid="complete-jobTitle-field"
                  placeholder="Enter your job title"
                />
                <ErrorMessage name="jobTitle" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>company</QuestionTitle>
                <Field
                  type="text"
                  name="company"
                  data-testid="complete-company-field"
                  placeholder="Enter your company"
                />
                <ErrorMessage name="company" component="div" />
              </QuestionContainer>
              <Button
                type="submit"
                disabled={isSubmitting}
                data-testid="complete-next"
              >
                Next
              </Button>
            </Form>
          </ContentContainer>
        )}
      </Formik>
    </div>
  )
}
