import React, { useEffect, useState } from 'react'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { getTermsAndConditions } from '../api'
import { Text } from '../styled/Text'

export const TermsAndConditions: React.FC = ({ history }: any) => {
  const [tcs, setTcs] = useState('loading...')
  useEffect(() => {
    getTermsAndConditions().then(({ data}: any) =>
      setTcs(data[0].terms_conditions_text)
    )
  }, [])

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Terms and Conditions</HeaderText>
      </OnboardingHeader>
      <ContentContainer>
        <Text style={{whiteSpace: 'pre-wrap'}}>{tcs}</Text>
      </ContentContainer>
    </div>
  )
}
