import { Table } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { getOwnBookings } from '../api'
import styled from 'styled-components'
import Orange from '../orange.svg'
import { Card } from '../styled/Card'
import { HeaderText } from '../styled/HeaderText'
import { format } from 'date-fns'

const DashboardHeader = styled.div`
  width: 100%;
  height: 200px;
  padding-top: 28px;
  margin-bottom: -100px;
  background-image: url(${Orange});
`
export const MemberDashboardMeetingRoomHistory: React.FC = ({
  history
}: any) => {
  const [bookings, setBookings] = useState([])
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getOwnBookings({ token }).then(bookingsRes => {
      setBookings(
        bookingsRes.filter(
          ({ endTime }: any) =>
            new Date().getTime() > new Date(endTime).getTime()
        )
      )
    })
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <a href={`/dashboard/meeting-rooms/${record.roomId}`}>{text}</a>
      )
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime: string) => format(startTime, 'h:mm a, Do MMMM YYYY')
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (endTime: string) => format(endTime, 'h:mm a, Do MMMM YYYY')
    }
  ]

  return (
    <div className="App">
      <DashboardHeader>
        <HeaderText style={{ color: 'white' }}>Meeting Room History</HeaderText>
      </DashboardHeader>
      <Card title={''} arrow={false} onPress={() => {}}>
        <div style={{ width: '100%', padding: '16px' }}>
          <Table columns={columns} dataSource={bookings} rowKey={'bookingId'} />
        </div>
      </Card>
    </div>
  )
}
