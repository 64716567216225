import { format } from 'date-fns'
import React from 'react'
import Calendar from 'react-calendar'
import { Link } from 'react-router-dom'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Progress } from '../styled/Progress'

export const OnboardingStartDate: React.FC = ({ setUserDetails }: any) => {
  const userDetails = useUserDetails()
  const startDate = (userDetails && userDetails.startDate) || new Date()

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Choose your start date</HeaderText>
      </OnboardingHeader>
      <ContentContainer>
        <Progress numberOfSteps={6} currentStep={5} />
        <Calendar
          onChange={date =>
            setUserDetails((state: any) => ({ ...state, startDate: date }))
          }
          value={startDate}
        />
        <p>
          You'll start your membership on{' '}
          <b>{format(startDate, 'Do MMMM YYYY')}</b>
        </p>
      </ContentContainer>

      <Link to={routes.acceptTermsAndConditions}>
        <Button data-testid="onboarding-next">Next</Button>
      </Link>
    </div>
  )
}
