import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { User } from '../interfaces'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Progress } from '../styled/Progress'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'

export const OnboardingName: React.FC = ({ history, setUserDetails }: any) => {
  const userDetails = useUserDetails()
  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Tell us about yourself</HeaderText>
      </OnboardingHeader>
      <Progress numberOfSteps={6} currentStep={3} />
      <Formik
        initialValues={{
          firstName: userDetails!.firstName,
          lastName: userDetails!.lastName
        }}
        validate={(values): User => {
          const errors: any = {}
          if (!values.firstName) {
            errors.firstName = 'Required'
          }
          if (!values.lastName) {
            errors.lastName = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            ...values
          }))
          history.push(routes.details)
        }}
      >
        {({ isSubmitting }) => (
          <ContentContainer>
            <Form>
              <QuestionContainer>
                <QuestionTitle>first name</QuestionTitle>
                <Field
                  type="text"
                  name="firstName"
                  autoFocus={true}
                  data-testid="onboarding-firstName-field"
                  placeholder="Enter your first name"
                />
                <ErrorMessage name="firstName" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>last name</QuestionTitle>
                <Field
                  type="text"
                  name="lastName"
                  data-testid="onboarding-lastName-field"
                  placeholder="Enter your last name"
                />
                <ErrorMessage name="lastName" component="div" />
              </QuestionContainer>
              <Button
                type="submit"
                data-testid="onboarding-next"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </Form>
          </ContentContainer>
        )}
      </Formik>
    </div>
  )
}
