import styled from 'styled-components'
import BackArrowBlack from '../back-arrow-black.svg'

export const BackArrow = styled.button`
  width: 32px;
  height: 32px;
  margin-left: 16px;
  background-image: url(${BackArrowBlack});
  background-repeat: no-repeat;
  background-position: center;
  border: 0 none;
  background-color: transparent;
`
