import React from 'react'
import styled from 'styled-components'

export const ContentContainer: React.FC = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 480px;
  margin-top: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`
