import styled from 'styled-components'

export const HeaderText = styled.h1`
  font-size: 24px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #5a595e;
`
