import React from 'react'
import styled, { css } from 'styled-components'

export const AdminCard = ({
  children,
  title,
  onPress,
  testID
}: {
  children: React.ReactChild | React.ReactChild[]
  title: string
  onPress?: () => void
  testID?: string
}): JSX.Element => (
  <OutSideContainer onClick={onPress} data-testid={testID}>
    <Title>{title}</Title>
    {children}
  </OutSideContainer>
)

const OutSideContainer = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  padding: 16px;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  ${(props: any) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `}
`

const Title = styled.div`
  margin: 16px;
  text-align: left;
  font-size: 24px;
  line-height: 24px;
  color: #373f49;
`
