import React from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { routes } from '../App'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { SomeCTAButton } from '../styled/SomeCTAButton'

export const MemberDashboardBillingUnpaidInvoiceChargeAgain: React.FC = () => {
  const { history } = useReactRouter()

  return (
    <div className="App">
      <OnboardingHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ width: '48px' }} />
          <HeaderText>Unpaid Invoice</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <div style={{ padding: '16px', width: '100%' }}>
        <div style={{ padding: '16px', width: '100%' }}>
          <Text>We've initiated the charge on your card.</Text>
        </div>
        <SomeCTAButton
          onClick={() => history.push(routes.memberDashboardBilling)}
        >
          Done
        </SomeCTAButton>
      </div>
    </div>
  )
}
