import { Icon, Timeline } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryStack } from 'victory'
import {
  getUser,
  GetUserResponse,
  getUserInvoices,
  Invoice,
  InvoiceStatus,
  adminGetMeetingRoomQuotaForUser,
  adminPreviewInvoiceForUser,
  getNotes,
  makeNote
} from '../api'
import { routes } from '../App'
import Briefcase from '../Briefcase.svg'
import MailIcon from '../MailIcon.svg'
import PhoneIcon from '../PhoneIcon.svg'
import { AdminNav } from '../styled/AdminNav'
import { Breadcrumb } from '../styled/Breadcrumb'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { isSameMonth, format } from 'date-fns'
import { MeetingRoomQuota } from '../interfaces'
import { getFirstOfNextMonth } from '../utils/getFirstOfNextMonth'
import { AdminInvoiceTable } from '../components/AdminInvoiceTable'
import { MemberAvatar } from '../components/MemberAvatar';

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
`

const TopContainer = styled.div`
  background: #f7fbfd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 16px;
  display: flex;
  align-items: center;
`

const BottomContainer = styled.div`
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 32px;
`

const NameText = styled.h2`
  font-size: 24px;
  line-height: 43px;
  margin: 0;
  color: #373f49;
`

const SubTextBold = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #737e8c;
`

const SubText = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: #737e8c;
  margin: 0;
`

const CardTitleText = styled.h3`
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  color: #737e8c;
  margin-bottom: 13px;
`

const CardAmount = styled.h2`
  text-transform: uppercase;
  margin: 0;
  font-size: 32px;
  line-height: 32px;
  color: #373f49;
  margin-bottom: 13px;
`

const CardSubText = styled.h3`
  font-size: 16px;
  line-height: 16px;
  color: #828282;
  margin: 0;
`

const OccupationText = styled.h3`
  font-size: 16px;
  line-height: 16px;
  padding-left: 8px;
  color: #828282;
  margin: 0;
`

const SmallCard = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
  max-width: 240px;
`

const BigCard = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
`

const TimeLineDateTime = styled.div`
  color: #6b737a;
`
const TimeLineTitle = styled.div`
  color: #283237;
`
const TimeLineNote = styled.div``

const viewMonths = [
  { month: new Date(2019, 0), amount: 0 },
  { month: new Date(2019, 1), amount: 0 },
  { month: new Date(2019, 2), amount: 0 },
  { month: new Date(2019, 3), amount: 0 },
  { month: new Date(2019, 4), amount: 0 },
  { month: new Date(2019, 5), amount: 0 },
  { month: new Date(2019, 6), amount: 0 },
  { month: new Date(2019, 7), amount: 0 },
  { month: new Date(2019, 8), amount: 0 },
  { month: new Date(2019, 9), amount: 0 },
  { month: new Date(2019, 10), amount: 0 },
  { month: new Date(2019, 11), amount: 0 }
]

const AdminWrapper: React.FC<{ history: any }> = ({ children, history }) => (
  <div className="Admin-App">
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%'
      }}
    >
      <AdminNav history={history} />
      <div style={{ width: '100%', padding: '16px' }}>
        <Breadcrumb
          routes={[
            {
              name: 'members',
              url: routes.adminDashboardMembers
            },
            {
              name: 'member profile',
              url: routes.adminDashboardMemberProfile
            }
          ]}
        />
        <Container>{children}</Container>
      </div>
    </div>
  </div>
)

export const AdminDashboardMemberProfile: React.FC = ({
  history,
  match
}: any) => {
  const tabs = ['summary', 'invoices', 'activity']
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [memberProfile, setMemberProfile] = useState<GetUserResponse>()
  const [memberInvoices, setMemberInvoices] = useState<Invoice[]>([])
  const [quota, setQuota] = useState<MeetingRoomQuota>()
  const [previewInvoice, setPreviewInvoice] = useState<Invoice>()
  const [noteValue, setNoteValue] = useState('')
  const [notes, setNotes] = useState([])
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    Promise.all([
      getUserInvoices(match.params.memberId, { token }),
      getUser(match.params.memberId, { token }),
      adminGetMeetingRoomQuotaForUser(match.params.memberId, { token }),
      adminPreviewInvoiceForUser(match.params.memberId, { token }),
      getNotes(match.params.memberId, { token })
    ]).then(
      ([
        memberInvoices,
        { data: memberProfileData },
        { data: mrQuotaData },
        { data: invoiceData },
        { data: notes }
      ]) => {
        setMemberProfile(memberProfileData)
        setMemberInvoices(memberInvoices)
        setQuota(mrQuotaData)
        setPreviewInvoice(invoiceData)
        setNotes(notes)
      }
    )
  }, [match.params.memberId])

  const saveNote = () => {
    const token = window.localStorage.getItem('Token')
    makeNote(
      {
        id: match.params.memberId,
        note: noteValue,
        date_taken: new Date()
      },
      { token }
    )
      .then(() => {
        setNoteValue('')
        return getNotes(match.params.memberId, { token })
      })
      .then(({ data: notes }) => setNotes(notes))
  }

  const totalRevenueData = viewMonths.map(({ month }) => ({
    month,
    amount: memberInvoices
      .filter(({ status }) => status === InvoiceStatus.Paid)
      .filter(({ issued_on }) => isSameMonth(new Date(issued_on), month))
      .reduce((acc, { subtotal }) => acc + subtotal, 0)
  }))

  const unPaidInvoices = memberInvoices
    .filter(
      ({ status }) =>
        status === InvoiceStatus.Declined || status === InvoiceStatus.Pending
    )
    .reduce((acc, { subtotal }) => acc + subtotal, 0)

  if (memberProfile == null) {
    return (
      <AdminWrapper history={history}>
        <CenteredSpinner />
      </AdminWrapper>
    )
  }

  // for the below data fields, start with the default/empty text and change it if we have data

  let memberNameText = memberProfile.email
  if (memberProfile.first_name !== '' || memberProfile.last_name !== '') {
    memberNameText = `${memberProfile.first_name} ${memberProfile.last_name}`
  }

  let memberPlanText = 'No Plan'
  if (memberProfile.user_profile.plan && memberProfile.user_profile.plan.name) {
    memberPlanText = `Active ${memberProfile.user_profile.plan.name} plan at Riff`
  }

  let memberStartDateText = 'No recorded start date'
  if (memberProfile.user_profile.start_date) {
    memberStartDateText = `Member since ${
      memberProfile.user_profile.start_date
    }`
  }

  const timelineItems = () => {
    const noteItems = notes.map((n: any) => ({
      icon: 'align-left',
      date: n.date_taken,
      data: n.note,
      title: 'Note'
    }))
    const invoiceItems = memberInvoices.map((n: any) => ({
      icon: 'message',
      date: n.issued_on,
      data: `Invoice ${n.id} ${n.status}`,
      title: 'Invoice'
    }))
    return noteItems.concat(invoiceItems).sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    })
  }

  return (
    <AdminWrapper history={history}>
      <TopContainer>
        <MemberAvatar firstName={memberProfile.first_name}/>
        <Flex p={3} flex={1} flexDirection={'column'}>
          <Flex alignItems={'center'}>
            <NameText>{memberNameText}</NameText>
            <OccupationText>
              • {memberProfile.user_profile.job_title || 'No listed occupation'}
            </OccupationText>
          </Flex>
          <SubTextBold>
            {memberPlanText}. {memberStartDateText}.
          </SubTextBold>
          <SubText>{memberProfile.user_profile.about_you || 'No bio'}</SubText>
        </Flex>
        <Flex p={3} flexDirection="column">
          <Flex alignItems={'center'}>
            <img src={MailIcon} alt="email" style={{ padding: '8px' }} />
            <SubText>{memberProfile.email}</SubText>
          </Flex>
          <Flex alignItems={'center'}>
            <img src={PhoneIcon} alt="phone" style={{ padding: '8px' }} />
            <SubText>
              {memberProfile.user_profile.mobile_number || 'No phone number'}
            </SubText>
          </Flex>
          <Flex alignItems={'center'}>
            <img src={Briefcase} alt="company" style={{ padding: '8px' }} />
            <SubText>
              {memberProfile.user_profile.company || 'No company'}
            </SubText>
          </Flex>
        </Flex>
      </TopContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          background: '#f7fbfd',
          paddingLeft: '64px'
        }}
      >
        {tabs.map((name, i) =>
          i === activeTabIndex ? (
            <div
              style={{
                textTransform: 'uppercase',
                fontSize: '14px',
                color: '#737E8C',
                background: '#FFFFFF',
                padding: '4px 24px',
                borderRadius: '4px 4px 0px 0px'
              }}
            >
              {name}
            </div>
          ) : (
            <div
              onClick={() => setActiveTabIndex(i)}
              style={{
                cursor: 'pointer',
                textTransform: 'uppercase',
                fontSize: '14px',
                padding: '4px 24px',
                color: '#737E8C'
              }}
            >
              {name}
            </div>
          )
        )}
      </div>
      {/* {activeTabIndex === 3 ? (
        <BottomContainer>
          <p style={{ height: '100%' }}>Member is on the x plan</p>
          <Button>Cancel Plan</Button>
        </BottomContainer>
      ) :  */}
      {activeTabIndex === 2 ? (
        <BottomContainer>
          <input
            placeholder="Add note"
            value={noteValue}
            onChange={e => setNoteValue(e.target.value)}
          />
          <button style={{ margin: '16px' }} onClick={saveNote}>
            save note
          </button>
          <Timeline>
            {timelineItems().map(({ icon, title, date, data }, i) => (
              <Timeline.Item key={i} dot={<Icon type={icon} />}>
                <TimeLineDateTime>
                  {format(date, 'DD-MM-YYYY h:mm:ss A')}
                </TimeLineDateTime>
                <TimeLineTitle>{title}</TimeLineTitle>
                <TimeLineNote>{data}</TimeLineNote>
              </Timeline.Item>
            ))}
          </Timeline>
        </BottomContainer>
      ) : activeTabIndex === 1 ? (
        <BottomContainer>
          {/* <Table columns={columns} dataSource={invoices} /> */}
          <AdminInvoiceTable invoices={memberInvoices} />
        </BottomContainer>
      ) : (
        <BottomContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '32px 0px'
            }}
          >
            <SmallCard>
              <CardTitleText>upcoming invoice</CardTitleText>
              <CardAmount>
                {previewInvoice ? '$' + previewInvoice.amount_charged : '-'}
              </CardAmount>
              <CardSubText>on {getFirstOfNextMonth()}</CardSubText>
            </SmallCard>
            <SmallCard>
              <CardTitleText>unpaid invoices</CardTitleText>
              <CardAmount>${unPaidInvoices}</CardAmount>
            </SmallCard>
            <SmallCard>
              <CardTitleText>booking credits</CardTitleText>
              <CardAmount>
                {quota
                  ? parseFloat(quota.credits_remaining) > 0
                    ? quota.credits_remaining
                    : 0
                  : '-'}
              </CardAmount>
              <CardSubText>Remaining this month</CardSubText>
            </SmallCard>
          </div>
          <BigCard>
            <CardTitleText>Customer revenue</CardTitleText>
            <VictoryChart
              height={300}
              width={560}
              domainPadding={{ x: 30, y: 30 }}
              domain={{ y: [0, 100] }}
            >
              <VictoryStack colorScale={['283237', '8671F3', 'D2C9F9']}>
                <VictoryBar
                  data={totalRevenueData.map(data => ({
                    x: format(data.month, 'MMM'),
                    y: data.amount
                  }))}
                />
              </VictoryStack>
              <VictoryAxis
                dependentAxis
                tickFormat={(tick: any) => `${tick}`}
              />
              <VictoryAxis
                tickFormat={totalRevenueData.map(({ month }) =>
                  format(month, 'MMM')
                )}
              />
            </VictoryChart>
          </BigCard>
        </BottomContainer>
      )}
    </AdminWrapper>
  )
}
