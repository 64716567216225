import { Avatar } from 'antd'
import React from 'react'

export const MemberAvatar: React.FC<{ firstName?: string }> = ({
  firstName
}) => {
  if (firstName) {
    return (
      <Avatar size={128} shape="square" style={{fontSize: '72px'}}>
        {firstName[0].toUpperCase()}
      </Avatar>
    )
  }
  return <Avatar size={128} shape="square" icon="user" style={{fontSize: '72px'}}/>
}
