import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from 'rebass'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { getInvoice, Invoice, InvoiceStatus } from '../api'
import { routes } from '../App'
import { BackArrow } from '../styled/BackArrow'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { SomeCTAButton } from '../styled/SomeCTAButton';

const Title = styled(Text)({
  textAlign: 'left',
  fontSize: '18px',
  color: '#525961'
})

const Value = styled.div({
  textAlign: 'left',
  fontSize: '18px',
  color: '#8B96A3',
  textTransform: 'capitalize'
})

const TitleValue: React.FC<{ title: string; value: string }> = ({
  title,
  value
}) => (
  <Box mb={3}>
    <Title mb={1}>{title}</Title>
    <Value>{value}</Value>
  </Box>
)

const InvoiceDetails: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  return (
    <div style={{ padding: '16px', width: '100%' }}>
      <TitleValue
        title="Date Issued"
        value={new Date(invoice.issued_on).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })}
      />
      <TitleValue title="Status" value={invoice.status.toLocaleLowerCase()} />
      <TitleValue title="Amount" value={'$' + invoice.amount_charged} />

      {invoice.paid_on && (
        <TitleValue title="Date Paid" value={invoice.paid_on} />
      )}
    </div>
  )
}

export const MemberDashboardBillingInvoice: React.FC = () => {
  const { history, match } = useReactRouter<{ invoiceId: string }>()
  const [invoice, setInvoice] = useState<Invoice>()

  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getInvoice({ token, invoiceId: match.params.invoiceId }).then(
      ({ data }) => {
        setInvoice(data)
      }
    )
  }, [match.params.invoiceId])

  return (
    <div className="App">
      <OnboardingHeader>
        <Flex justifyContent="space-between">
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>
            {invoice == null ? 'Invoice' : `Invoice ${invoice.id}`}
          </HeaderText>
          <div style={{ width: '48px' }} />
        </Flex>
      </OnboardingHeader>
      <div style={{ padding: '16px', width: '100%' }}>
        {invoice == null ? (
          <CenteredSpinner />
        ) : (
          <>
            <InvoiceDetails invoice={invoice} />
            <SomeCTAButton
              onClick={() =>
                history.push(`/dashboard/billing/invoice/${invoice.id}/view`)
              }
            >
              View Invoice
            </SomeCTAButton>
            {(invoice.status === InvoiceStatus.Issued ||
              invoice.status === InvoiceStatus.Declined) && (
              <SomeCTAButton
                onClick={() =>
                  history.push(
                    routes.memberDashboardBillingUnpaidInvoiceChargeAgain
                  )
                }
              >
                Pay Invoice
              </SomeCTAButton>
            )}
          </>
        )}
      </div>
    </div>
  )
}
