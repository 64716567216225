import React from 'react'
import { routes } from '../App'
import { Plan } from '../interfaces'
import { Card } from '../styled/Card'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Progress } from '../styled/Progress'
import { Text } from '../styled/Text'
import { usePlans } from '../utils/apiHooks';

export const OnboardingChoosePlan: React.FC = ({
  history,
  setUserDetails
}: any) => {
  const plans = usePlans()

  const handleSelectPlan = ({ id }: { id: number }) => {
    setUserDetails((currentValues: any) => ({
      ...currentValues,
      ...{ plan: Number(id) }
    }))
    history.push(routes.email)
  }

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Join us at Riff</HeaderText>
      </OnboardingHeader>
      <Progress numberOfSteps={6} currentStep={1} />
      <ContentContainer>
        <b
          style={{
            margin: '16px',
            width: '100%',
            fontSize: '24px',
            lineHeight: '29px',
            textAlign: 'left'
          }}
        >
          Choose your plan
        </b>
        {plans.map(({ id, name, description, rate_string }: Plan) => (
          <Card
            title={name}
            key={id}
            onPress={() => handleSelectPlan({ id })}
            testID={name}
          >
            <Text color={'black'} verticalSpacing={16}>
              {description}
            </Text>
            <Text color={'Gray'} verticalSpacing={16}>
              {rate_string}
            </Text>
          </Card>
        ))}
      </ContentContainer>
    </div>
  )
}
