import React from 'react'
import styled, { css } from 'styled-components'
import { routes } from '../App'

export const AdminNav: React.FC<{ history: any }> = ({ history }) => (
  <OutSideContainer>
    <Title>Spacecubed Admin</Title>
    <FlexColumnBetween>
      <NavLinksMain>
        <Link onClick={() => history.push(routes.adminDashboardMeetingRooms)}>
          Meeting Rooms
        </Link>
        <Link
          onClick={() => history.push(routes.adminDashboardAwaitingMembers)}
        >
          Requests
        </Link>
        <Link onClick={() => history.push(routes.adminDashboardMembers)}>
          All Members
        </Link>
        <Link onClick={() => history.push(routes.adminDashboardInvoices)}>
          Finance
        </Link>
        <Link onClick={() => history.push(routes.adminDashboardUnpaidInvoices)}>
          Unpaid Invoices
        </Link>
      </NavLinksMain>
      <NavLinksFooter>
        {/* <Link>Settings</Link> */}
      </NavLinksFooter>
    </FlexColumnBetween>
  </OutSideContainer>
)

const OutSideContainer = styled.div`
  position: relative;
  width: 200px;
  background: #373f49;
  padding: 16px;
  border: 2px solid #373f49;
  ${(props: any) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `}
`

const Title = styled.div`
  margin: 16px;
  text-align: left;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
`

const FlexColumnBetween = styled.div`
  display: flex;
  height: calc(100vh - 128px);
  flex-direction: column;
  justify-content: space-between;
`

const NavLinksMain = styled.div`
  display: flex;
  text-align: left;
  margin: 16px;
  margin-right: 0px;
  flex-direction: column;
`
const NavLinksFooter = styled.div`
  text-align: left;
  margin: 16px;
`

const Link = styled.a`
  font-size: 18px;
  line-height: 36px;
  color: #b4bec8;
`
