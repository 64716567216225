import { addDays, format, isSameDay, startOfDay } from 'date-fns'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { getBookingsUser, getCoworkerId, getMeetingRoomsUser } from '../api'
import { routes } from '../App'
import Orange from '../orange.svg'
import Placeholder from '../placeholder.svg'
import { BookingBar } from '../styled/BookingBar'
import { Card } from '../styled/Card'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { HeaderText } from '../styled/HeaderText'
import { SelectOption } from '../styled/SelectOption'
import { USER_SUPPORT_LINK } from '../utils/constants'

const DashboardHeader = styled.div`
  width: 100%;
  height: 200px;
  padding-top: 28px;
  margin-bottom: -100px;
  background-image: url(${Orange});
`

const next2WeekDates = () => {
  const d = startOfDay(new Date())
  const arr = [...Array(14)]
  return arr.map((_, i) => addDays(d, i))
}

const Wrapper: React.FC = ({ children }) => (
  <div className="App">
    <DashboardHeader>
      <HeaderText style={{ color: 'white' }}>Meeting Rooms</HeaderText>
    </DashboardHeader>
    {children}
  </div>
)

export const MemberDashboardMeetingRooms: React.FC = () => {
  const { history } = useReactRouter()
  const [dateIndex, setDateIndex] = useState(0)
  const [meetingRooms, setMeetingRooms] = useState([])
  const [bookings, setBookings] = useState([])
  const [coworkerId, setCoworkerId] = useState(0)
  const dates = next2WeekDates()
  const [error, setError] = useState<any>()

  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    Promise.all([
      getBookingsUser({ token }),
      getMeetingRoomsUser({ token }),
      getCoworkerId({ token })
    ])
      .then(([bookings, meetingRooms, coworkerId]: any) => {
        setCoworkerId(coworkerId)
        setBookings(bookings)
        setMeetingRooms(meetingRooms)
      })
      .catch(e => setError(e))
  }, [])
  const roomData = meetingRooms.map(({ name, roomId }: any) => ({
    id: roomId,
    name,
    location: 'Riff',
    bookedTimes: [],
    proposedTimes: []
  }))
  const res = bookings.filter(({ startTime }: any) =>
    isSameDay(new Date(dates[dateIndex]), new Date(startTime))
  )

  const roomBookingData = roomData.map(
    ({ id, name, location, bookedTimes, proposedTimes }) => {
      return {
        id,
        name,
        location,
        bookedTimes: res
          .filter(({ roomId }) => roomId === id)
          .filter(({ bookedById }) => bookedById !== coworkerId)
          .map(({ startTime, endTime }) => ({
            startTime:
              new Date(startTime).getTime() -
              new Date(dates[dateIndex]).getTime(),
            endTime:
              new Date(endTime).getTime() - new Date(dates[dateIndex]).getTime()
          })),
        proposedTimes: res
          .filter(({ roomId }) => roomId === id)
          .filter(({ bookedById }) => bookedById === coworkerId)
          .map(({ startTime, endTime }) => ({
            startTime:
              new Date(startTime).getTime() -
              new Date(dates[dateIndex]).getTime(),
            endTime:
              new Date(endTime).getTime() - new Date(dates[dateIndex]).getTime()
          }))
      }
    }
  )

  const roomView = roomBookingData

  if (error) {
    return (
      <Wrapper>
        Your Spacecubed profile needs to be connected to the legacy meeting room
        bookings system. Please{' '}
        <a href={USER_SUPPORT_LINK}>contact the admin.</a>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Card title={''} arrow={false}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <SelectOption
            name={'date'}
            items={dates.map(o => format(o, 'Do MMM YYYY'))}
            selectedIndex={dateIndex}
            onSelect={setDateIndex}
            data-testid="select-date-filter"
          />
        </div>
        <div>
          <button
            style={{
              minWidth: '89px',
              background: '#A4D4E9',
              borderRadius: '28px',
              border: '0 none',
              color: 'white',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '16px',
              paddingRight: '16px',
              margin: '4px',
              marginTop: '16px'
            }}
          >
            Available
          </button>
          <button
            style={{
              minWidth: '89px',
              background: '#D3DDEB',
              borderRadius: '28px',
              border: '0 none',
              color: 'white',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '16px',
              paddingRight: '16px',
              margin: '4px',
              marginTop: '16px'
            }}
          >
            All
          </button>
          <a href={routes.memberDashboardMeetingRoomHistory}>
            View Meeting Room History
          </a>
        </div>
      </Card>
      {roomView.length === 0 && <CenteredSpinner />}
      {roomView.map(({ id, name, location, bookedTimes, proposedTimes }) => (
        <>
          <Card title={''} arrow={false} padding={false}>
            <div
              style={{ height: '100%', width: '100%', display: 'flex' }}
              data-testid={`meeting-room-${id}`}
              onClick={() =>
                history.push(
                  routes.memberDashboardMeetingRoomView.split(':')[0] +
                    id.toString() +
                    '?day=' +
                    `${new Date(dates[dateIndex]).getTime()}`
                )
              }
            >
              <img src={Placeholder} alt="placeholder" />
              <div style={{ padding: '16px' }}>
                <h2
                  style={{
                    color: '#000000',
                    fontSize: '24px',
                    margin: '0px',
                    textAlign: 'left'
                  }}
                >
                  {name}
                </h2>
                <p
                  style={{
                    color: '#707A85',
                    fontSize: '14px',
                    textAlign: 'left'
                  }}
                >
                  {location}
                </p>
              </div>
            </div>
            <div
              style={{
                borderBottom: '14px'
              }}
            >
              <BookingBar
                bookedTimes={bookedTimes}
                proposedTimes={proposedTimes}
                borderBottom={true}
              />
            </div>
          </Card>
        </>
      ))}
    </Wrapper>
  )
}
