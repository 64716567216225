import React from 'react'
import { routes } from '../App'
import { BackArrow } from '../styled/BackArrow'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { SomeCTAButton } from '../styled/SomeCTAButton'
import { SomeParagraph } from '../styled/SomeParagraph'

export const MemberDashboardChangePlanSuccess: React.FC = ({
  history
}: any) => {
  return (
    <div className="App">
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Change plan</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <div style={{ padding: '16px' }}>
        <SomeParagraph>
          We’re processing your plan change request now. You’ll receive an email
          from a Spacecubed admin with further details on your new plan
        </SomeParagraph>

        <SomeCTAButton onClick={() => history.push(routes.memberDashboardHome)}>
          Done
        </SomeCTAButton>
      </div>
    </div>
  )
}
