import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { getUnapprovedMembers, getUserDetails, login } from '../api'
import { routes } from '../App'
import { SignupStatus, User } from '../interfaces'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'

export const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const { history } = useReactRouter()
  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Spacecubed Platform</HeaderText>
      </OnboardingHeader>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validate={(values): User => {
          const errors: any = {}
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }
          if (!values.password) {
            errors.password = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          setErrorMessage('')
          login({ email: values.email, password: values.password })
            .then(({ data: { key } }) => {
              window.localStorage.setItem('Token', key)
              return getUnapprovedMembers({ token: key })
                .then(() => history.push(routes.adminDashboardAwaitingMembers))
                .catch(() => {
                  return getUserDetails({ token: key }).then(({ data }) => {
                    const userDetails = data[0]
                    if (
                      userDetails.signup_status ===
                      SignupStatus.AwaitingApproval
                    ) {
                      return history.push(routes.memberAwaitingApproval)
                    } else if (userDetails.job_title !== null) {
                      return history.push(routes.memberDashboardHome)
                    } else {
                      return history.push(routes.completeLanding)
                    }
                  })
                })
            })
            .catch(e => {
              setSubmitting(false)
              setErrorMessage('Incorrect Username or Password')
            })
        }}
      >
        {({ isSubmitting }) => (
          <ContentContainer>
            <Form>
              <QuestionContainer>
                <QuestionTitle>email</QuestionTitle>
                <Field
                  type="text"
                  name="email"
                  data-testid="login-email-field"
                  placeholder="Enter your email"
                  autoFocus={true}
                />
                <ErrorMessage name="email" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>password</QuestionTitle>
                <Field
                  type="password"
                  name="password"
                  data-testid="login-password-field"
                  placeholder="Type your password here"
                />
                <ErrorMessage name="password" component="div" />
              </QuestionContainer>
              <Button
                type="submit"
                disabled={isSubmitting}
                data-testid="login-button"
              >
                Login
              </Button>
              <Link to={routes.plan}>
                <a
                  style={{ fontSize: 16 }}
                  href={routes.plan}
                  data-testid="login-create-account"
                >
                  Create an account
                </a>
              </Link>
              {errorMessage === '' ? (
                <div style={{ height: '40px' }} />
              ) : (
                <p style={{ color: 'red', height: '40px' }}>{errorMessage}</p>
              )}
            </Form>
          </ContentContainer>
        )}
      </Formik>
    </div>
  )
}
