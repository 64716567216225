import { Table, Tag } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';
import { Invoice, InvoiceStatus } from '../api';
import { getInvoiceStatusColor } from '../pages/AdminDashboardInvoices';
import { Link} from 'react-router-dom'

export const AdminInvoiceTable: React.FC<{
         invoices: Invoice[]
       }> = ({ invoices }) => {
         return (
           <Table dataSource={invoices}>
             <Column title="Invoice" dataIndex="id" key="id" render={(id: string) => <Link to={`/admin/dashboard/invoice/${id}/view`}>{id}</Link>}/>
             <Column
               title="Amount"
               dataIndex="amount_charged"
               key="amount_charged"
               render={(text: string) => (text ? '$' + text : '-')}
             />
             <Column
               title="Status"
               dataIndex="status"
               key="status"
               render={(status: InvoiceStatus) => (
                 <Tag color={getInvoiceStatusColor(status)}>
                   {status}
                 </Tag>
               )}
             />
             <Column
               title="Date Issued"
               dataIndex="issued_on"
               key="issued_on"
               render={(issuedOn: string) =>
                 new Date(issuedOn).toLocaleDateString()
               }
             />
           </Table>
         )
       }
