import 'antd/dist/antd.css'
import React from 'react'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { AdminNav } from '../styled/AdminNav'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { useInvoiceHTML } from '../utils/apiHooks'

const Invoice = styled.div`
  width: 100%;

  > .invoice-box {
    background: white;
    max-width: 80%;
  }
`

export const AdminDashboardInvoiceView: React.FC = () => {
  const { match, history } = useReactRouter<{ invoiceId: string }>()
  const invoiceId = match.params.invoiceId

  const invoiceHTML = useInvoiceHTML(invoiceId)

  return (
    <div className="Admin-App">
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <AdminNav history={history} />
        <div style={{ width: '100%', padding: '16px' }}>
          {invoiceHTML == null ? (
            <CenteredSpinner />
          ) : (
            <Invoice dangerouslySetInnerHTML={{ __html: invoiceHTML }} />
          )}
        </div>
      </div>
    </div>
  )
}
