import 'antd/dist/antd.css'
import React from 'react'
import useReactRouter from 'use-react-router'
import { routes } from '../App'
import { AdminInvoiceTable } from '../components/AdminInvoiceTable'
import { AdminCard } from '../styled/AdminCard'
import { AdminNav } from '../styled/AdminNav'
import { Breadcrumb } from '../styled/Breadcrumb'
import { useInvoices } from '../utils/apiHooks'
import { invoiceMapping } from '../utils/invoiceMapping'

export const AdminDashboardUnpaidInvoices: React.FC = () => {
  const { history } = useReactRouter()
  const invoices = useInvoices()

  const unpaidInvoices = invoices.filter(i =>
    invoiceMapping.unpaid.includes(i.status)
  )

  return (
    <div className="Admin-App">
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <AdminNav history={history} />
        <div style={{ width: '100%', padding: '16px' }}>
          <Breadcrumb
            routes={[
              {
                name: 'dashboard',
                url: routes.adminDashboard
              },
              {
                name: 'unpaid invoices',
                url: routes.adminDashboardUnpaidInvoices
              }
            ]}
          />
          <AdminCard title={'Review unpaid invoices'}>
            <AdminInvoiceTable invoices={unpaidInvoices} />
          </AdminCard>
        </div>
      </div>
    </div>
  )
}
