import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Route {
  name: string
  url: string
}

const SomeText = styled.h3`
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #737e8c;
  margin-right: 4px;
`
const OutSideContainer = styled.div`
  padding: 8px;
  display: flex;
`

export const Breadcrumb = ({ routes }: { routes: Route[] }): JSX.Element => (
  <OutSideContainer>
    {routes.map((r, i) =>
      i === 0 ? (
        <Link to={r.url}>
          <SomeText>{r.name}</SomeText>
        </Link>
      ) : (
        <>
          <SomeText>{' > '}</SomeText>
          <Link to={r.url}>
            <SomeText>{r.name}</SomeText>
          </Link>
        </>
      )
    )}
  </OutSideContainer>
)
