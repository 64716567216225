import React from 'react'
import styled, { css } from 'styled-components'

interface Time {
  startTime: number
  endTime: number
}

const day = 24 * 60 * 60 * 1000
// width: `${(endTime - startTime / day) * 100}%`

export const BookingBar = ({
  bookedTimes,
  proposedTimes,
  borderBottom = false,
  withTimes = false
}: {
  bookedTimes: Time[]
  proposedTimes: Time[]
  borderBottom?: boolean
  withTimes?: boolean
}): JSX.Element => (
  <>
    {withTimes && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        {['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm', '12am'].map(
          (t, i) => (
            <div key={i}>{t}</div>
          )
        )}
      </div>
    )}
    <OutSideContainer borderBottom={borderBottom}>
      <EmptyCellsNoBorder style={{}} />
      {[...Array(23)].map((_, i) => (
        <EmptyCells key={i} />
      ))}
      {proposedTimes.map(({ startTime, endTime }) => (
        <div
          key={startTime}
          style={{
            position: 'absolute',
            zIndex: 1,
            left: `${(startTime / day) * 100}%`, // if you want to start the scale at 6am, use: ${((startTime - 21600000) / day) * 100}%
            backgroundColor: '#5B85F9',
            height: '16px',
            width: `${((endTime - startTime) / day) * 100}%`
          }}
        />
      ))}
      {bookedTimes.map(({ startTime, endTime }) => (
        <div
          key={startTime}
          style={{
            position: 'absolute',
            zIndex: 1,
            left: `${(startTime / day) * 100}%`, // if you want to start the scale at 6am, use: ${((startTime - 21600000) / day) * 100}%
            background: `repeating-linear-gradient(
            135deg,
            #e1e9f5,
            #e1e9f5 3px,
            #F3B0AF 3px,
            #F3B0AF 6px
          )`,
            height: '16px',
            width: `${((endTime - startTime) / day) * 100}%`
          }}
        />
      ))}
    </OutSideContainer>
  </>
)

const OutSideContainer = styled.div<{ borderBottom: boolean }>`
  height: 16px;
  position: relative;
  width: 100%;
  background-color: #e1e9f5;
  display: flex;
  ${props =>
    props.borderBottom &&
    css`
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    `}
`

const EmptyCells = styled.div`
  position: relative;
  border-left: 1px solid #c4cfe0;
  z-index: 2;
  flex: 24;
  width: 100%;
`

const EmptyCellsNoBorder = styled.div`
  position: relative;
  flex: 24;
  width: 100%;
`
