import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import { routes } from '../App'
import { Plan } from '../interfaces'
import { BackArrow } from '../styled/BackArrow'
import { Button } from '../styled/Button'
import { Card } from '../styled/Card'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { SomeCTAButton } from '../styled/SomeCTAButton'
import { usePlans, useUserProfile } from '../utils/apiHooks'

const SomeParagraph = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: #373f49;
  text-align: left;
`

const SomeText = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: #8b96a3;
  text-align: left;
`

const Wrapper: React.FC = ({ children }) => {
  const { history } = useReactRouter()
  return (
    <div className="App">
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Membership</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      {children}
    </div>
  )
}

export const MemberDashboardMembership: React.FC = () => {
  const { history } = useReactRouter()
  const userDetails = useUserProfile()
  const plans = usePlans()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  useEffect(() => {
    if (userDetails && plans.length > 0) {
      setSelectedPlan(plans.find(plan => plan.id === userDetails.plan))
    }
  }, [userDetails, plans])

  if (selectedPlan == null) {
    return (
      <Wrapper>
        <CenteredSpinner />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div
        style={{
          textAlign: 'left',
          fontSize: '18px',
          color: '#525961',
          width: '100%',
          marginLeft: '16px',
          marginTop: '16px'
        }}
      >
        Your Plan
      </div>
      <Card title={selectedPlan.name} arrow={false} onPress={() => {}}>
        <SomeParagraph>{selectedPlan.description}</SomeParagraph>
        <SomeText>{selectedPlan.rate_string}</SomeText>
      </Card>
      <SomeCTAButton
        onClick={() => history.push(routes.memberDashboardChangePlan)}
      >
        Change Plan
      </SomeCTAButton>
      <SomeCTAButton
        onClick={() => history.push(routes.memberDashboardCancelPlan)}
      >
        Cancel Plan
      </SomeCTAButton>
    </Wrapper>
  )
}
