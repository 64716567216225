import { Table, Tag } from 'antd'
import 'antd/dist/antd.css'
import React, { useEffect, useState } from 'react'
import { approveMember, getUnapprovedMembers } from '../api'
import { AdminCard } from '../styled/AdminCard'
import { AdminNav } from '../styled/AdminNav'

export const AdminDashboardAwaitingMembers: React.FC = ({ history }: any) => {
  const [unapprovedMembers, setUnapprovedMembers] = useState([])
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getUnapprovedMembers({ token }).then(({ data }) =>
      setUnapprovedMembers(data)
    )
  }, [])
  const handleApproval = (id: number) => {
    const token = window.localStorage.getItem('Token')
    return approveMember(id, { token }).then(({ data }) =>
      setUnapprovedMembers(data)
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
      // render: (text: any) => <a href="javascript:;">{text}</a>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'is_approved',
      render: (is_approved: any) =>
        is_approved ? (
          <Tag color={'green'}>{'APPROVED'}</Tag>
        ) : (
          <Tag color={'red'}>{'REQUESTED'}</Tag>
        )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <span>
          {record.is_approved ? null : (
            <button
              style={{
                background: 'none',
                color: '#1890ff',
                border: 'none',
                padding: '0!important',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              data-testid={`approve-${record.email}`}
              onClick={() => handleApproval(record.id)}
            >
              Approve
            </button>
          )}
        </span>
      )
    }
  ]

  const data = unapprovedMembers.map(({ user_profile, ...rest }: any) => ({
    ...user_profile,
    ...rest
  }))

  return (
    <div className="Admin-App">
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <AdminNav history={history} />
        <div style={{ width: '100%', padding: '16px' }}>
          <AdminCard title={'New Member Requests'}>
            <Table columns={columns} dataSource={data} />
          </AdminCard>
        </div>
      </div>
    </div>
  )
}
