import axios from 'axios'
import { MeetingRoomQuota, UserProfile } from './interfaces'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const createNewUser = ({
  username,
  email,
  password1,
  password2,
  first_name,
  last_name
}: {
  username: string
  email: string
  password1: string
  password2: string
  first_name: string
  last_name: string
}) =>
  axios.post('/api/v1/rest-auth/registration/', {
    username,
    email,
    password1,
    password2,
    first_name,
    last_name
  })

export const login = ({
  email,
  password
}: {
  email: string
  password: string
}) =>
  axios.post('/api/v1/rest-auth/login/', {
    username: email,
    password
  })

interface UserDetailsRequest {
  id?: number
  plan?: number
  profile_picture_url?: string
  mobile_number?: string
  postcode?: number
  start_date?: string
  job_title?: string
  company?: string
  interests?: number[]
  skills?: Array<{ id: number; name: string }>
  about_you?: string
  agreed_tc?: number
}

export interface GetUserResponse {
  url: string
  id: number
  email: string
  first_name: string
  last_name: string
  username: string
  name: string
  user_profile: {
    id: number
    profile_picture_url: any
    mobile_number: any
    postcode: any
    start_date: any
    job_title: any
    company: any
    about_you: any
    signup_status: string
    approved_on: string
    gst_chargeable: true
    is_linked_to_nexudus: false
    onboarded_on: any
    last_charged_on: any
    plan: any
    approved_by: {
      id: number
      password: string
      last_login: string
      is_superuser: false
      username: string
      first_name: string
      last_name: string
      email: string
      is_staff: true
      is_active: true
      date_joined: string
      user_profile: number
      groups: any[]
      user_permissions: any[]
    }
    onboarded_by: any
    interests: any[]
    skills: any[]
  }
}

export enum InvoiceStatus {
  Building = 'BUILDING',
  Issued = 'ISSUED',
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Error = 'ERROR',
  Paid = 'PAID',
  Credited = 'CREDITED',
  Cancelled = 'CANCELLED'
}

export interface Invoice {
  id: number
  user: number
  subtotal_charged: string
  tax_charged: string
  amount_charged: string
  amount_paid?: string
  plan_title: string
  status: InvoiceStatus
  paid_on?: string
  issued_on: string
  subtotal: number
  invoice_history: number[]
}

export const updateUserDetails = (
  userDetails: Partial<UserDetailsRequest>,
  { token }: { token: string | null }
) =>
  axios.patch(`/api/v1/profile/`, userDetails, {
    headers: { Authorization: `Token ${token}` }
  })

export const getUser = (id: number, { token }: { token: string | null }) =>
  axios.get(`/api/v1/users/${id}`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: GetUserResponse }>

export const getUserDetails = ({ token }: { token: string | null }) =>
  axios
    .get(`/api/v1/profile/`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then(({ data }: any) => ({
      data: [
        {
          ...data[0],
          interests:
            data[0].interests && data[0].interests.length > 0
              ? JSON.parse(data[0].interests)
              : [],
          skills:
            data[0].skills && data[0].skills.length > 0
              ? JSON.parse(data[0].skills)
              : []
        }
      ]
    })) as Promise<{ data: UserProfile[] }>

export const getInterests = ({ token }: { token: string | null }) =>
  axios.get('/api/v1/interests/', {
    headers: { Authorization: `Token ${token}` }
  })

export const getSkills = ({ token }: { token: string | null }) =>
  axios.get('/api/v1/skills/', {
    headers: { Authorization: `Token ${token}` }
  })

export const getUnapprovedMembers = ({ token }: { token: string | null }) =>
  axios.get('/api/v1/users-unapproved/', {
    headers: { Authorization: `Token ${token}` }
  })

export const getMyInvoices = ({ token }: { token: string | null }) =>
  axios.get('/api/v1/invoices/', {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: Invoice[] }>

export const getUserInvoices = (
  id: string,
  { token }: { token: string | null }
) =>
  axios
    .get(`/api/v1/invoices/`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then(({ data }: any) => {
      return data.filter(({ user }) => user === parseInt(id, 10))
    }) as Promise<Invoice[]>

export const getInvoice = ({
  token,
  invoiceId
}: {
  token: string | null
  invoiceId: string
}) =>
  axios.get(`/api/v1/invoices/${invoiceId}`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: Invoice }>

export const getInvoicePreview = ({ token }: { token: string | null }) =>
  axios.get(`/api/v1/invoices/preview_next_invoice`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: Invoice }>

export const getMembers = ({ token }: { token: string | null }) =>
  axios.get('/api/v1/users-approved/', {
    headers: { Authorization: `Token ${token}` }
  })

export const getRemainingMeetingRoomQuota = ({
  token
}: {
  token: string | null
}) =>
  axios.get(`/api/v1/quotas/remaining_quota/`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: MeetingRoomQuota }>

export const adminGetMeetingRoomQuotaForUser = (
  userId: string,
  {
    token
  }: {
    token: string | null
  }
) =>
  axios.get(`/api/v1/users/${userId}/remaining_quota`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: MeetingRoomQuota }>

export const adminPreviewInvoiceForUser = (
  userId: string,
  {
    token
  }: {
    token: string | null
  }
) =>
  axios.get(`/api/v1/users/${userId}/preview_invoice`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: Invoice }>

export const approveMember = (
  id: number,
  { token }: { token: string | null }
) =>
  axios.post(
    `/api/v1/users-unapproved/${id}/approve_user/`,
    {},
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const onboardMember = (
  id: number,
  { token }: { token: string | null }
) =>
  axios.post(
    `/api/v1/users-awaiting-onboarding/${id}/onboard_user/`,
    {},
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const getNotes = (id: number, { token }: { token: string | null }) =>
  axios.post(
    `/api/v1/notes/user_notes/`,
    { user_id: id },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const makeNote = (
  { id, note, date_taken }: any,
  { token }: { token: string | null }
) =>
  axios.post(
    `/api/v1/notes/make_note/`,
    { user_id: id, note, date_taken },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

interface LinkMemberNexudus {
  userId: number
  coworkerId: number
  nexudusUserId: number
  wifiPassword: string
}

export const linkMemberNexudus = (
  { userId, coworkerId, nexudusUserId, wifiPassword }: LinkMemberNexudus,
  { token }: { token: string | null }
) =>
  axios.post(
    `api/v1/nexudus/nexudus-profile/`,
    {
      user_id: userId,
      coworker_id: coworkerId,
      nexudus_user_id: nexudusUserId,
      wifi_password: wifiPassword
    },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const getTermsAndConditions = () =>
  axios.get(`api/v1/terms-and-conditions`)

export const getCoworkerId = ({ token }: { token: string | null }) =>
  axios
    .get(`api/v1/nexudus-id`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then(({ data }) => parseInt(data.coworker_id, 10))

export const getOwnBookings = ({ token }: { token: string | null }) =>
  axios
    .get(`api/v1/nexudus-bookings`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then((result: any) => {
      return result.data.Records.map((resource: any) => ({
        roomId: resource.ResourceId,
        bookingId: resource.UniqueId,
        name: resource.ResourceName,
        bookedBy: resource.CoworkerFullName,
        bookedById: resource.CoworkerId,
        startTime: resource.FromTime,
        endTime: resource.ToTime,
        bookingType:
          resource.ResourceTypeName === 'Desk' ? 'desk' : 'meetingroom'
      }))
    })

export const bookMeetingRoomUser = (
  { nexudusResourceId, startTime, endTime }: any,
  { token }: { token: string | null }
) =>
  axios.post(
    `api/v1/meeting-rooms/user/`,
    {
      nexudus_resource_id: nexudusResourceId,
      start_time: startTime,
      end_time: endTime
    },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const userRequestPlanChange = (
  newPlanId: number,
  { token }: { token: string | null }
) =>
  axios.post(
    `api/v1/profile/request_change_plan/`,
    {
      new_plan: newPlanId
    },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const userRequestCancelMembership = ({
  token
}: {
  token: string | null
}) =>
  axios.post(
    `api/v1/profile/request_cancel_membership/`,
    {},
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const getMeetingRoomsAdmin = ({ token }: { token: string | null }) =>
  axios
    .get(`api/v1/meeting-rooms-admin/`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then((result: any) => {
      return result.data.Records.map((resource: any) => ({
        roomId: resource.Id,
        name: resource.Name
      }))
    })

export const getBookingsAdmin = ({ token }: { token: string | null }) =>
  axios
    .get(`api/v1/bookings-admin/`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then((result: any) => {
      return result.data.Records.map((resource: any) => ({
        roomId: resource.ResourceId,
        bookingId: resource.UniqueId,
        name: resource.ResourceName,
        bookedBy: resource.CoworkerFullName,
        bookedById: resource.CoworkerId,
        startTime: resource.FromTime,
        endTime: resource.ToTime,
        bookingType:
          resource.ResourceTypeName === 'Desk' ? 'desk' : 'meetingroom'
      }))
    })

export const getBookingsUser = ({ token }: { token: string | null }) =>
  axios
    .get(`api/v1/bookings-user/`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then((result: any) => {
      return result.data.Records.map((resource: any) => ({
        roomId: resource.ResourceId,
        bookingId: resource.UniqueId,
        name: resource.ResourceName,
        bookedBy: resource.CoworkerFullName,
        bookedById: resource.CoworkerId,
        startTime: resource.FromTime,
        endTime: resource.ToTime,
        bookingType:
          resource.ResourceTypeName === 'Desk' ? 'desk' : 'meetingroom'
      }))
    })

export const getMeetingRoomsUser = ({ token }: { token: string | null }) =>
  axios
    .get(`api/v1/meeting-rooms-user/`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then((result: any) => {
      return result.data.Records.map((resource: any) => ({
        roomId: resource.Id,
        name: resource.Name
      }))
    })
export const bookMeetingRoomAdmin = (
  { nexudusResourceId, fromTime, toTime, userEmail }: any,
  { token }: { token: string | null }
) =>
  axios.post(
    `api/v1/meeting-rooms/admin`,
    {
      nexudus_resource_id: nexudusResourceId,
      start_time: fromTime,
      end_time: toTime,
      user_email: userEmail
    },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const saveBillingDetails = (
  stripeToken: string,
  { token }: { token: string | null }
) => {
  axios.defaults.xsrfCookieName = 'csrftoken'
  axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
  return axios.post(
    `/api/v1/payment-gateway/create/`,
    { stripeToken },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )
}

let plans: any
export const getPlans = () => {
  if (plans) {
    return Promise.resolve(plans)
  } else {
    return axios.get('/api/v1/plans/')
  }
}

export const sendEmailForApproval = ({ token }: { token: string | null }) =>
  axios.post(
    `/api/v1/users/send_submission/`,
    {},
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const getInvoiceHTML = ({
  token,
  invoiceId
}: {
  token: string | null
  invoiceId: string
}) =>
  axios.get(`/api/v1/invoices/${invoiceId}/view/`, {
    headers: { Authorization: `Token ${token}` }
  })
