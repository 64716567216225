import styled, { css } from 'styled-components'

interface TextProps {
  color?: string
  verticalSpacing?: number
}

export const Text = styled.div<TextProps>`
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  ${(props: TextProps) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${(props: TextProps) =>
    props.verticalSpacing &&
    css`
      margin-top: ${props.verticalSpacing}px;
      margin-bottom: ${props.verticalSpacing}px;
    `}
`
