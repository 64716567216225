import React from 'react'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { Text } from '../styled/Text'
import { USER_SUPPORT_LINK } from '../utils/constants';

export const MemberAwaitingApproval: React.FC = () => {
  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Awaiting approval</HeaderText>
      </OnboardingHeader>
      <ContentContainer>
        <Text>
          Your membership application is awaiting approval from an admin. If you have any questions, please <a href={USER_SUPPORT_LINK}>get in touch</a>.
        </Text>
      </ContentContainer>
    </div>
  )
}
