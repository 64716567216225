import React, { useContext } from 'react'
import { User } from './interfaces'

export const UserDetailsContext = React.createContext<User | undefined>(
  undefined
)

export function useUserDetails() {
  return useContext(UserDetailsContext)
}
