import { useEffect, useState } from 'react'
import {
  getInvoiceHTML,
  getInvoicePreview,
  getMyInvoices,
  getPlans,
  getRemainingMeetingRoomQuota,
  getUserDetails,
  Invoice
} from '../api'
import { MeetingRoomQuota, Plan, UserProfile } from '../interfaces'
import { invoiceMapping } from './invoiceMapping'

export function useTotalUnpaidInvoices(): number | undefined {
  const [totalUnpaidInvoices, setTotalUnpaidInvoices] = useState<number>()

  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getMyInvoices({ token }).then(({ data: invoices }) => {
      const unpaidInvoices = invoices.filter(i =>
        invoiceMapping.unpaid.includes(i.status)
      )
      const totalUnpaidAmount = unpaidInvoices.reduce<number>(
        (sum, invoice) => sum + parseInt(invoice.amount_charged, 10),
        0
      )
      setTotalUnpaidInvoices(totalUnpaidAmount)
    })
  }, [])

  return totalUnpaidInvoices
}

export function useInvoicePreview(): Invoice | undefined {
  const [invoicePreview, setInvoicePreview] = useState<Invoice>()
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getInvoicePreview({ token }).then(({ data }) => setInvoicePreview(data))
  }, [])

  return invoicePreview
}

export function useRemainingMeetingRoomQuota(): MeetingRoomQuota | undefined {
  const [meetingRoomQuota, setMeetingRoomQuota] = useState<MeetingRoomQuota>()
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getRemainingMeetingRoomQuota({ token }).then(({ data }) =>
      setMeetingRoomQuota(data)
    )
  }, [])

  return meetingRoomQuota
}

export function useInvoiceHTML(invoiceId: string): string | undefined {
  const [invoiceHTML, setInvoiceHTML] = useState<string>()
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getInvoiceHTML({ token, invoiceId }).then(({ data }) => {
      setInvoiceHTML(data)
    })
  }, [invoiceId])

  return invoiceHTML
}

export function useInvoices(): Invoice[] {
  const [invoices, setInvoices] = useState<Invoice[]>([])
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getMyInvoices({ token }).then(({ data }) => setInvoices(data))
  }, [])

  return invoices
}

export function useUserProfile(): UserProfile | undefined {
  const [userProfile, setUserProfile] = useState<UserProfile>()
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getUserDetails({ token }).then(({ data }) => setUserProfile(data[0]))
  }, [])

  return userProfile
}

export function usePlans(): Plan[] {
  const [plans, setPlans] = useState<Plan[]>([])

  useEffect(() => {
    getPlans().then(({ data }: { data: Plan[] }) => setPlans(data))
  }, [])

  return plans
}
