import React from 'react'
import { routes } from '../App'
import { BackArrow } from '../styled/BackArrow'
import { Card } from '../styled/Card'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'

export const MemberDashboardBillingInfo: React.FC = ({ history }: any) => {
  return (
    <div className="App">
      <OnboardingHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BackArrow onClick={() => history.goBack()} />
          <HeaderText>Billing Info</HeaderText>
          <div style={{ width: '48px' }} />
        </div>
      </OnboardingHeader>
      <Card
        title={'Edit Payment Method'}
        arrow={true}
        onPress={() => {
          history.push(routes.memberDashboardBillingUpdate)
        }}
      >
        <div />
      </Card>
    </div>
  )
}
