import React from 'react'
import styled, { css } from 'styled-components'

export const OnboardingHeader: React.FC = styled.header`
  top: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  padding-top: 28px;
  padding-bottom: 28px;
  color: #5a595e;
  background: #ffffff;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.05);
  ${(props: any) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `}
`
