import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  format,
  isSameDay,
  startOfDay
} from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import {
  bookMeetingRoomUser,
  getBookingsUser,
  getCoworkerId,
  getMeetingRoomsUser
} from '../api'
import Orange from '../orange.svg'
import { BookingBar } from '../styled/BookingBar'
import { Card } from '../styled/Card'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { HeaderText } from '../styled/HeaderText'
import { SelectOption } from '../styled/SelectOption'
import { SomeCTAButton } from '../styled/SomeCTAButton';

const DashboardHeader = styled.div`
  width: 100%;
  height: 200px;
  padding-top: 28px;
  margin-bottom: -100px;
  background-image: url(${Orange});
`

const next2WeekDates = () => {
  const d = startOfDay(new Date())
  const arr = [...Array(14)]
  return arr.map((_, i) => addDays(d, i))
}

const makeStartTime = (date: Date): Date[] => {
  const d = addHours(startOfDay(date), 6)
  const arr = [...Array(60)]
  const times = arr.map((_, i) => addMinutes(d, i * 15))
  const now = new Date()
  if (isSameDay(date, now)) {
    return times.filter(time => time > now)
  } else {
    return times
  }
}

const lengthOfTime = [
  '15 mins',
  '30 mins',
  '45 mins',
  '1 hour',
  '1 hour 15 mins',
  '1 hour 30 mins',
  '1 hour 45 mins',
  '2 hours'
]

const SomeButton = styled.button`
  border: 2px solid #a4d4e9;
  box-sizing: border-box;
  color: #a4d4e9;
  font-size: 14px;
  width: 150px;
  line-height: 14px;
  text-align: center;
  border-radius: 28px;
  background: white;
`

const SomeParagraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #707a85;
  text-align: left;
`

const SomeHeading = styled.h3`
  background: transparent;
  font-size: 16px;
  padding: 0px;
  width: 100%;
  padding-left: 8px;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 0px;
  line-height: 19px;
  background: #41474d;
`

export const MemberDashboardMeetingRoomView: React.FC = ({
  history,
  location,
  setUserDetails
}: any) => {
  const meetingRoomId = parseInt(
    location.pathname.split('/meeting-rooms/')[1],
    10
  )

  let dayIndex = 0
  if (location.search) {
    dayIndex = differenceInDays(
      startOfDay(new Date(parseInt(location.search.split('?day=')[1], 10))),
      startOfDay(new Date())
    )
  }

  const [dateIndex, setDateIndex] = useState(dayIndex)
  const [startTimeIndex, setStartTimeIndex] = useState(0)
  const [lengthOfTimeIndex, setLengthOfTimeIndex] = useState(0)
  const [meetingRooms, setMeetingRooms] = useState([])
  const [bookings, setBookings] = useState<any[]>([])
  const [coworkerId, setCoworkerId] = useState(0)
  const [isMakingBooking, setIsMakingBooking] = useState<boolean>(false)
  const dates = next2WeekDates()

  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    Promise.all([
      getBookingsUser({ token }),
      getMeetingRoomsUser({ token }),
      getCoworkerId({ token })
    ]).then(([bookings, meetingRooms, coworkerId]: any) => {
      setCoworkerId(coworkerId)
      setBookings(bookings)
      setMeetingRooms(meetingRooms)
    })
  }, [])

  const roomData = meetingRooms
    .filter(({ roomId }) => meetingRoomId === roomId)
    .map(({ name, roomId }: any) => ({
      id: roomId,
      name,
      location: 'Riff',
      bookedTimes: [],
      proposedTimes: []
    }))

  const res = bookings
    .filter(({ roomId }) => meetingRoomId === roomId)
    .filter(({ startTime }: any) =>
      isSameDay(dates[dateIndex], new Date(startTime))
    )

  const roomIsBookedNow = !!res
    .filter(({ roomId }) => roomId === meetingRoomId)
    .find((booking: { startTime: any; endTime: any }) => {
      const now = new Date()
      return (
        new Date(booking.startTime) < now && new Date(booking.endTime) > now
      )
    })

  const roomBookingData = roomData.map(({ id, name, location }) => {
    return {
      id,
      name,
      location,
      bookedTimes: res
        .filter(({ roomId }) => roomId === id)
        .filter(({ bookedById }) => bookedById !== coworkerId)
        .map(({ startTime, endTime }) => ({
          startTime:
            new Date(startTime).getTime() -
            new Date(dates[dateIndex]).getTime(),
          endTime:
            new Date(endTime).getTime() - new Date(dates[dateIndex]).getTime()
        })),
      proposedTimes: res
        .filter(({ roomId }) => roomId === id)
        .filter(({ bookedById }) => bookedById === coworkerId)
        .map(({ startTime, endTime }) => ({
          startTime:
            new Date(startTime).getTime() -
            new Date(dates[dateIndex]).getTime(),
          endTime:
            new Date(endTime).getTime() - new Date(dates[dateIndex]).getTime()
        }))
    }
  })

  const filterRoomData = roomBookingData.find(
    ({ id }: any) => id === meetingRoomId
  )

  if (filterRoomData === undefined) {
    return (
      <div className="App">
        <DashboardHeader />
        <Flex mt={6}>
          <CenteredSpinner />
        </Flex>
      </div>
    )
  }
  const { name, bookedTimes, proposedTimes }: any = filterRoomData
  const startTimes = makeStartTime(dates[dateIndex])
  const handlebookRoom = () => {
    if (!isMakingBooking) {
      setIsMakingBooking(true)
      const startTime = startTimes[startTimeIndex]
      const endTime = addMinutes(
        startTimes[startTimeIndex],
        15 + 15 * lengthOfTimeIndex
      )
      const token = window.localStorage.getItem('Token')
      return bookMeetingRoomUser(
        { nexudusResourceId: meetingRoomId, startTime, endTime },
        { token }
      ).then(() =>
        getBookingsUser({ token }).then(res => {
          setBookings(res)
          setIsMakingBooking(false)
        })
      )
    }
  }

  return (
    <div className="App">
      <DashboardHeader>
        <HeaderText style={{ color: 'white' }}>{name}</HeaderText>
      </DashboardHeader>
      <Card title={''} arrow={false} onPress={() => {}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <SomeParagraph>A bookable space powered by Spacecubed.</SomeParagraph>
          <SomeButton>
            {roomIsBookedNow ? 'UNAVAILABLE NOW' : 'AVAILABLE NOW'}
          </SomeButton>
        </div>
      </Card>
      <SomeHeading>Availability</SomeHeading>
      <div
        style={{
          paddingBottom: '8px',
          paddingLeft: '8px',
          paddingRight: '8px',
          width: '100%'
        }}
      >
        <BookingBar
          bookedTimes={bookedTimes}
          proposedTimes={proposedTimes}
          withTimes={true}
        />
      </div>
      <SomeHeading>Make a booking</SomeHeading>
      <Card title={''} arrow={false} onPress={() => {}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '110px'
          }}
        >
          <SelectOption
            name={'date'}
            items={dates.map(o => format(o, 'Do MMM YYYY'))}
            selectedIndex={dateIndex}
            onSelect={setDateIndex}
          />

          <SelectOption
            name={'start'}
            items={startTimes.map(o => format(o, 'h:mm a'))}
            selectedIndex={startTimeIndex}
            onSelect={setStartTimeIndex}
          />
          <SelectOption
            name={'for'}
            items={lengthOfTime}
            selectedIndex={lengthOfTimeIndex}
            onSelect={setLengthOfTimeIndex}
          />
        </div>
        <SomeCTAButton onClick={handlebookRoom}>
          {isMakingBooking ? 'Making your booking...' : 'Make a booking'}
        </SomeCTAButton>
      </Card>
    </div>
  )
}
