import React from 'react'
import { ContentContainer } from '../styled/ContentContainer'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { format } from 'date-fns'
import { Text } from '../styled/Text'
import { useUserDetails } from '../context'

export const OnboardingThankyou: React.FC = () => {
  const userDetails = useUserDetails()

  return (
    <div className="App">
      <OnboardingHeader>Thankyou</OnboardingHeader>
      <ContentContainer>
        <Text>
          You’re starting a coworking membership on{' '}
          {format(userDetails!.startDate || new Date(), 'Do MMMM YYYY')}. A Riff
          administrator will be in touch via email to confirm your membership.
        </Text>
      </ContentContainer>
    </div>
  )
}
