export interface Plan {
  id: number
  name: string
  description: string
  rate: {
    id: number
    frequency: 'YEAR' | 'MONTHLY'
    value: number
  }
  rate_string: string
  order_of_preference: number
}

export interface Skill {
  id: number
  name: string
}

export interface Interest {
  id: number
  name: string
}

export interface User {
  id?: number
  email: string
  plan?: number
  firstName: string
  lastName: string
  mobileNumber: string
  postcode?: number
  startDate?: Date
  jobTitle?: string
  company?: string
  skills?: Skill[]
  interests?: Interest[]
  aboutYou?: string
  billingName?: string
  profilePictureUrl?: string
}

export enum SignupStatus {
  AwaitingApproval = 'awaiting_approval',
  AwaitingOnboarding = 'awaiting_onboarding',
  ApprovedAndOnboarded = 'approved_and_onboarded',
  Inactive = 'user_inactive'
}

export interface UserProfile {
  id: number
  plan?: number
  profile_picture_url?: null
  mobile_number?: string
  postcode?: number
  start_date?: string
  job_title?: string
  company?: string
  about_you?: string
  interests?: {
    id: number
    name: string
    description: string
  }
  skills?: {
    id: number
    name: string
    description: string
  }
  wifi_password?: string
  signup_status: SignupStatus
}

export interface MeetingRoomQuota {
  id: number
  user: number
  credits_remaining: string
  further_usage_cost_per_hour: string
  active: boolean
}
