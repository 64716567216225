import React from 'react'
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe
} from 'react-stripe-elements'
import { Button } from '../styled/Button'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'
import { Text } from '../styled/Text'

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '18px',
        color: '#8b96a3',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#c23d4b'
      }
    }
  }
}

class _CardForm extends React.Component<any> {
  public state = {
    errorMessage: ''
  }
  public handleChange = ({ error }: any) => {
    if (error) {
      this.setState({ errorMessage: error.message })
    }
  }
  public handleSubmit = (evt: any) => {
    evt.preventDefault()
    // @ts-ignore
    if (this.props.stripe) {
      // @ts-ignore
      this.props.stripe
        .createToken({ name: this.props.billingName })
        .then(this.props.handleResult)
    } else {
      console.log("Stripe.js hasn't loaded yet.")
    }
  }
  public render() {
    return (
      <div className="CardDemo">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <QuestionContainer>
            <QuestionTitle>credit/debit card number</QuestionTitle>
            <CardNumberElement {...createOptions()} />
          </QuestionContainer>
          <QuestionContainer>
            <QuestionTitle>expiry date</QuestionTitle>
            <CardExpiryElement {...createOptions()} />
          </QuestionContainer>
          <QuestionContainer>
            <QuestionTitle>cvc</QuestionTitle>
            <CardCVCElement {...createOptions()} />
          </QuestionContainer>

          <Button
            data-testid="complete-next"
            disabled={this.props.isSubmitting}
          >
            {this.props.isSubmitting ? 'Loading...' : this.props.actionButtonText}
          </Button>
        </form>
        {this.props.infoText && <Text>{this.props.infoText}</Text>}
        {this.props.errorText && (
          <Text color={'red'} verticalSpacing={20}>
            {this.props.errorText} Please update your card details and submit
            your payment again.
          </Text>
        )}
      </div>
    )
  }
}
export const CardForm = injectStripe<any>(_CardForm)
